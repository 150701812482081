import { gql } from '@apollo/client';

export const STORE_STATISTICS = gql`
    mutation StoreStatistics($statsType: String, $teamId: ID!){
        storeStatistics(statsType: $statsType, teamId: $teamId)
    }
`;

export type ExportStatsVars = {
  statsSessionId: string,
  target: string,
  exportFormat: string,
}
export type ExportStatsRes = {
  data: {
    exportStats: string,
  },
}

export type myStatsOrderType = {
  statsSessionId: string,
  order: number,
}

export const EXPORT_STATS = gql`
    mutation exportStats(
         $statsSessionId: ID!,
         $target: String!,
         $exportFormat: String!,
         ) {
            exportStats(
                statsSessionId: $statsSessionId,
                target: $target,
                exportFormat: $exportFormat,
            ) 
       }
`;

export const CREATE_OR_UPDATE_MY_STATS = gql`
    mutation CreateOrUpdateMyStats($statsSessionId: ID!, $myStatsName: String!, $showMyStats:Boolean, $showDashboard: Boolean){
      createOrUpdateMyStats(statsSessionId: $statsSessionId, myStatsName: $myStatsName, showMyStats: $showMyStats, showDashboard: $showDashboard)
    }
`;

export const REMOVE_FROM_MY_STATS = gql`
mutation removeFromMyStats($myStatsListId:[ID]!){
  res: removeFromMyStats(myStatsListId: $myStatsListId)
}
`;

export const DELETE_STATSESSION_ROW = gql`
mutation deleteStatsessionRow($statsessionRowId:ID!){
  res: deleteStatsessionRow(statsessionRowId: $statsessionRowId)
}
`;
