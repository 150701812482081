import React from 'react';
import FranceIcon from '@images/flags/fr.svg';
import ChinaIcon from '@images/flags/cn.svg';
import RussiaIcon from '@images/flags/ru.svg';
import ThaiIcon from '@images/flags/th.svg';
import GreatBritan from '@images/flags/gb.svg';
import PolandIcon from '@images/flags/pl.svg';
import SpainIcon from '@images/flags/esp.svg';
import JapanIcon from '@images/flags/jap.svg';
import KoreaIcon from '@images/flags/kor.svg';
import PortugalIcon from '@images/flags/port.svg';

export type userType = {
    firstname: string,
    lastName: string,
    email: string,
    preferredLanguage: string,
    isActive: boolean,
  }

export const defaultUser = {
  firstName: '',
  lastName: '',
  email: '',
  preferredLanguage: 'en-us',
  isActive: true,
};

export const defaultUserRole = {
  roleType: 'admin',
  clubId: '',
  teamId: null,
  playerSet: [],
  athleteId: null,
  roleId: 0,
  id: null,
};

export const countryFlags = {
  'en-us': <GreatBritan />,
  'fr-fr': <FranceIcon />,
  'pl-pl': <PolandIcon />,
  'ru-ru': <RussiaIcon />,
  'th-th': <ThaiIcon />,
  'zh-cn': <ChinaIcon />,
  'es-es': <SpainIcon />,
  'ja-jp': <JapanIcon />,
  'ko-ko': <KoreaIcon />,
  'pt-pt': <PortugalIcon />,
};

export const roles = ['admin', 'operator', 'supervisor', 'reader'];
