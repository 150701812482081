import { gql } from '@apollo/client';

export const GET_TREND_ID = gql`
  query getTrendId(
    $teamId: ID!
    $statssessionId: String
    $startDate: Date
    $endDate: Date
    $period: String
    $groupingBy: String
    $athleteIds: [ID]
    $kpi: [String]!
    $categoryIds: [ID]!
    $tagsIds: [ID]
    $role: ID
    $matchDistance: String
    $matchCycle: String
    $chartType: String
    ){
    res: stats(
      teamId: $teamId
      statssessionId: $statssessionId
      startDate: $startDate
      endDate: $endDate
      period: $period
      groupingBy: $groupingBy
      athleteIds: $athleteIds
      kpi: $kpi
      categoryIds: $categoryIds
      tagsIds: $tagsIds
      statsType: "trend"
      role: $role
      matchDistance: $matchDistance
      matchCycle: $matchCycle
      chartType: $chartType
      ) {
        trendData
      }
    }
`;

export const GET_TREND_SERIES = gql`
  query getTrendSeries(
    $statssessionRowId: ID!
    ){
    res: getTrendData(
      statssessionRowId: $statssessionRowId
      ) 
        {
          chartData {
            ... on StatsDataType {
          yValue{
            value
            uom
            unit
          }
          athlete
          role
          kpi {
            key
            group
            unit
          }
          categories
          xValue
        }
      }
          queryDataDict {
            startDate
            endDate
            period
            groupingBy
            athletes
            KPI
            categories
            tags
            chartType
            matchDistance
            matchCycle
            role
          }
          hasReprocessableAthletesessions
          showMyStats
          showDashboard
          title
        }        
      }
`;

export const GET_SUMMARY_ID = gql`
  query getSummaryId(
    $teamId: ID!
    $statssessionId: String
    $startDate: Date
    $endDate: Date
    $period: String
    $athleteIds: [ID]
    $kpi: [String]!
    $categoryIds: [ID]!
    $tagsIds: [ID]
    $matchDistance: String
    $matchCycle: String
  ){
    res: stats(
      teamId: $teamId
      statssessionId: $statssessionId
      startDate: $startDate
      endDate: $endDate
      period: $period
      athleteIds: $athleteIds
      kpi: $kpi
      categoryIds: $categoryIds
      tagsIds: $tagsIds
      matchDistance: $matchDistance
      matchCycle: $matchCycle
      statsType: "summary"
      ) {
        summaryData
        }        
    }
`;

export const GET_SUMMARY_SERIES = gql`
  query getSummarySeries(
    $statssessionRowId: ID!
    ){
    res: getSummaryData(
      statssessionRowId: $statssessionRowId
      ) 
        {
          chartData {
            ... on StatsDataType {
          yValue{
            value
            uom
            unit
          }
          athlete
          role
          kpi {
            key
            group
            unit
          }
          categories
          totalSessions
          xValue
        }
      }
          queryDataDict {
            startDate
            endDate
            period
            groupingBy
            athletes
            KPI
            categories
            tags
            chartType
            matchDistance
            matchCycle
            role
          }
          hasReprocessableAthletesessions
          showMyStats
          showDashboard
          title
        }        
      }
`;

export const GET_RADAR_ID = gql`
  query getRadarId(
    $teamId: ID!
    $statssessionId: String
    $startDate: Date
    $endDate: Date
    $period: String
    $athleteIds: [ID]
    $kpi: [String]!
    $categoryIds: [ID]!
    $tagsIds: [ID]
    $matchDistance: String
    $matchCycle: String
  ){
    res: stats(
      teamId: $teamId
      statssessionId: $statssessionId
      startDate: $startDate
      endDate: $endDate
      period: $period
      athleteIds: $athleteIds
      kpi: $kpi
      categoryIds: $categoryIds
      tagsIds: $tagsIds
      matchDistance: $matchDistance
      matchCycle: $matchCycle
      statsType: "radar"
      ) {
        radarData
      }
    }
`;

export const GET_RADAR_SERIES = gql`
  query getRadarSeries(
    $statssessionRowId: ID!
  ){
    res: getRadarData(
      statssessionRowId: $statssessionRowId
      ) 
      {
        chartData {
          ... on RadarDataType {
          series {
            kpiLabel {
              key
              group
            }
            value
            unit
            uom
          }
        }
      }
          queryDataDict {
            startDate
            endDate
            period
            groupingBy
            athletes
            KPI
            categories
            tags
            chartType
            matchDistance
            matchCycle
            role
          }
          hasReprocessableAthletesessions
          showMyStats
          showDashboard
          title
      }
    }
`;

export const PING_STATS = gql`
  query pingStats{
    res: pingStats
 }
`;

export const CHECK_STATS_READY = gql`
  query checkStatsReady(
    $statssessionRowId: ID!
  ){
    res: checkStatsReady(
      statssessionRowId: $statssessionRowId
      ) 
    }
`;

export interface MyStatsTypeInfo {
  statsSessionDate: string
  statsSessionId: string
  statsSessionName: string
  statsSessionRowsIds: string[]
  statsSessionType: string
  showDashboard: boolean
  statsSessionsData: object[]
  width: number
}

export interface MyStatsRes {
  res: {
    myStats: MyStatsTypeInfo[]
    myStatsCount: number
  }
}

export interface MyStatsVars {
  teamId: string
  statsLimit?: number
}

export const MY_STATS = gql`
  query getMyStats(
    $teamId: ID!
    $statsLimit: Int
  ){
    res: myStats(
      teamId: $teamId
      statsLimit: $statsLimit
      )
      {
      myStatsCount
      myStats{
        statsSessionId  
        statsSessionRowsIds
        statsSessionType
        statsSessionName
        statsSessionDate
        showMyStats
        showDashboard
      }
    }
  }
`;
