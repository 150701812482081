import React from 'react';
import GroundLPS from './GroundLPS';
import { GroundType } from '../../models/ground';
import GroundGPS from './GroundGPS';
import { PlayersColors, SeriesState } from './WebPlayer';
import { PlayersDetails } from './PlayersTable';

interface Props {
  currentActivePlayer: number | null;
  hoverPlayer: number | null;
  defaultPlayersColor: string;
  enabledPlayers: Set<number>;
  ground: GroundType;
  jerseyOrNumber: boolean;
  handlePlayerClick: (playerID: number) => void;
  handlePlayerEnter?: (playerID: number) => void;
  handlePlayerLeave?: (playerID: number) => void;
  playerLinks: Set<[number, number]>;
  playersColors: PlayersColors;
  playersDetails: PlayersDetails;
  handleRemoveLink: (idx: number) => void;
  series: SeriesState;
  showVertices?: boolean;
  trailsEnabled: boolean;
}

export const timestampSearchRange = 200; // ms, max cursor proximity range
export const markerTrailLength = 3000; // ms, trail length

const Ground = (props: Props) => {
  const boxRef = React.useRef<HTMLDivElement>(null);

  const {
    currentActivePlayer,
    hoverPlayer,
    defaultPlayersColor,
    enabledPlayers,
    ground,
    jerseyOrNumber,
    handlePlayerClick,
    handlePlayerEnter,
    handlePlayerLeave,
    playerLinks,
    playersColors,
    playersDetails,
    handleRemoveLink,
    series,
    showVertices,
    trailsEnabled,
  } = props;

  const isGPSGround = ground?.groundCoordsType !== 'LOCAL';

  return (
    <div style={{ border: '1px solid #ddd', overflow: 'hidden', position: 'relative' }} ref={boxRef}>
      {
        ground?.id
        && (
          <>
            {
              !isGPSGround
              && (
                <GroundLPS
                  activePlayer={currentActivePlayer}
                  hoverPlayer={hoverPlayer}
                  defaultPlayersColor={defaultPlayersColor}
                  enabledPlayers={enabledPlayers}
                  groundId={String(ground.id)}
                  jerseyOrNumber={jerseyOrNumber}
                  onPlayerClick={handlePlayerClick}
                  onPlayerEnter={handlePlayerEnter}
                  onPlayerLeave={handlePlayerLeave}
                  playerLinks={playerLinks}
                  playersColors={playersColors}
                  playersDetails={playersDetails}
                  removeLink={handleRemoveLink}
                  series={series}
                  trailsEnabled={trailsEnabled}
                />
              )
            }

            {
              isGPSGround && (
                <GroundGPS
                  activePlayer={currentActivePlayer}
                  hoverPlayer={hoverPlayer}
                  defaultPlayersColor={defaultPlayersColor}
                  enabledPlayers={enabledPlayers}
                  groundId={String(ground.id)}
                  jerseyOrNumber={jerseyOrNumber}
                  onPlayerClick={handlePlayerClick}
                  onPlayerEnter={handlePlayerEnter}
                  onPlayerLeave={handlePlayerLeave}
                  playerLinks={playerLinks}
                  playersColors={playersColors}
                  playersDetails={playersDetails}
                  removeLink={handleRemoveLink}
                  series={series}
                  showVertices={showVertices}
                  trailsEnabled={trailsEnabled}
                />
              )
            }
          </>
        )
      }
    </div>
  );
};

export default Ground;
