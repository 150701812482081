import React, { memo, useMemo } from 'react';
import { Box } from '@material-ui/core';
import { GridCellParams } from '@material-ui/x-grid';
import VisibilityIcon from '@images/icons/svg/visibility.svg';
import NoVisibilityIcon from '@images/icons/svg/noVisibility.svg';
import ColorPicker from 'components/form/ColorPicker';
import { PLAYER_MARKER_COLOR } from 'components/track/ground/constants';
import { Loader } from 'lib/icons';
import { useTranslation } from 'react-i18next';
import { getPairedGroundColor } from 'models/ground';
import { convertToTitle } from 'components/utils/utils';
import { STATUS_TEXT } from './WebPlayer';
import { AthleteSessionType } from '../../models/athlete_session';
import Table, { PropsIn as TableProps } from '../Table';

type StatusKeys = keyof typeof STATUS_TEXT;
type Status = typeof STATUS_TEXT[StatusKeys];

export interface PlayerDetails {
  id: number;
  name: string;
  number: number | null;
  shortName: string;
}

export type PlayersDetails = Record<string, PlayerDetails>;

interface PlayersTableProps {
  athleteSessions: AthleteSessionType[];
  enabledPlayers: Array<number>;
  setEnabledPlayers: React.Dispatch<React.SetStateAction<Set<number>>>
  jerseyOrNumber: boolean;
  playersDetails: PlayersDetails;
  status: Status;
  groundSurfaceColor: string;
  playersColors: Record<string, string>;
  setPlayersColors: React.Dispatch<React.SetStateAction<Record<string, string>>>;
}

const PlayersTable = (props: PlayersTableProps) => {
  const {
    athleteSessions,
    enabledPlayers,
    setEnabledPlayers,
    playersDetails,
    status,
    setPlayersColors,
    groundSurfaceColor,
  } = props;


  const enabledPlayersSet = useMemo(() => new Set(enabledPlayers), [enabledPlayers]);

  const { t } = useTranslation();

  const handlePlayersEnable = (checked: boolean, athId: number) => {
    const newSet = new Set(enabledPlayersSet);
    if (checked) {
      newSet.add(athId);
    } else {
      newSet.delete(athId);
    }

    setEnabledPlayers(newSet);
  };

  const rows = useMemo(() => (athleteSessions.length ? athleteSessions.map((athlete) => ({
    id: athlete.id,
    name: athlete?.athlete?.name || '',
    number: playersDetails[athlete.id] && playersDetails[athlete.id].number || '',
    shortName: athlete?.athlete?.shortName || '',
  })) : []), [athleteSessions, playersDetails, enabledPlayers, status]);

  const tableProps: TableProps = useMemo(() => ({
    columns: [
      {
        field: 'visibility',
        headerClassName: 'hide-table-header',
        width: 52,
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        hideSortIcons: true,
        renderCell: (params: GridCellParams) => {
          const isEnabled = enabledPlayersSet.has(parseInt(params.row.id, 10));
          return (
            status !== STATUS_TEXT.PLAYERS_READY ? <Loader className="players-loading" />
              : (
                <Box onClick={() => handlePlayersEnable(!isEnabled, parseInt(params.row.id, 10))}>
                  {isEnabled ? <VisibilityIcon /> : <NoVisibilityIcon />}
                </Box>
              )
          );
        },
      },
      {
        field: 'color',
        headerClassName: 'hide-table-header',
        headerName: undefined,
        width: 52,
        headerAlign: 'center',
        align: 'center',
        filterable: false,
        sortable: false,
        renderCell: (params: GridCellParams) => (
          <ColorPicker
            style={{ position: 'absolute', margin: '10px' }}
            width="23px"
            height="23px"
            onChange={(color) => setPlayersColors((prevState) => ({
              ...prevState,
              [params.row.id]: color,
            }))}
            defaultColor={groundSurfaceColor ? getPairedGroundColor(groundSurfaceColor) : PLAYER_MARKER_COLOR}
            disableGradientPicker
            showDefaultColors
          />
        ),
      },
      {
        field: 'athlete',
        headerClassName: 'hide-table-header',
        headerName: t('table.athlete', 'athlete'),
        flex: 1,
        headerAlign: 'left',
        align: 'left',
        filterable: false,
        sortable: false,
        renderCell: (params: GridCellParams) => (
          <span className="nolink-col-left">
            {params.row.number ? `${params.row.number} - ${params.row.shortName}` : `${params.row.shortName}`}
          </span>
        ),
      },
    ],
    rows,
  }), [enabledPlayersSet, enabledPlayers, athleteSessions, groundSurfaceColor, setPlayersColors, rows, status]);

  return (
    <Box p={2} marginLeft="-20px">
      <Table
        loading={rows.length === 0}
        hideTopFilters
        compactRows
        compactHeader
        footerTotalText={`${convertToTitle(t('athlete.title', 'athletes'))}: `}
        {...tableProps}
      />
    </Box>
  );
};

export default memo(PlayersTable);
