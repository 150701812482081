import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import { useQueryCached } from 'components/utils/graphql';
import { EVENTS_LIST } from '../../query/track';
import DropdownButton from '../DropdownButton';

interface PropsIn {
  className?: string;
  athleteSessionId?: string;
  trackId?: string;
  onChange: (events: string[]) => void;
  preset?: string;
}

export default function SelectEvents(props: PropsIn) {
  const [events, setEvents] = useState([]);
  const {
    className, athleteSessionId, trackId, onChange, preset,
  } = props;
  const { t } = useTranslation();
  const { data } = useQueryCached(EVENTS_LIST(trackId ? 'track' : 'session'), {
    variables: trackId
      ? {
        trackId,
      }
      : {
        id: athleteSessionId,
      },
    skip: !trackId && !athleteSessionId,
    /*
      * Apollo Client bug, using useEffect hook to update data

      onCompleted: () => (data) => {
        console.log('useQuery onCompleted', data)
        if (data.res?.eventsList?.length > 0) {
          console.log(data.res?.eventsList, props.preset.split(','), data.res.eventsList.filter((s) => props.preset.split(',').includes(s)))
          setEvents(data.res.eventsList.filter((s) => props.preset.split(',').includes(s)))
        }
      },
    */
  });

  const handleClick = (e) => {
    if (events.length < 4) {
      if (events.includes(e)) {
        setEvents(events.filter((s) => s !== e));
      } else {
        setEvents([...events, e]);
      }
    }
  };

  useEffect(() => {
    onChange(events);
  }, [events]);

  useEffect(() => {
    if (data?.res?.eventsList?.length > 0) {
      setEvents(data.res.eventsList.filter((s) => (preset?.split(',') || []).includes(s)));
    }
  }, [data]);

  return (
    <Box className={className}>
      <DropdownButton
        label={t('track.widgets.labels.events', 'events')}
        multiple
        multipleSelection
        popoverContent={
          data?.res?.eventsList?.map((name) => ({
            value: name,
            label: t(`series.${name}.label`, { defaultValue: name }),
            onClick: () => handleClick(name),
          })) || []
        }
        small
        values={events}
        maxValues={3}
      />
    </Box>
  );
}
