import {
  AthleteAndRolesRes, ATHLETES, ATHLETES_AND_ROLES, AthleteTypeRes, AthleteTypeVars,
} from 'query/athlete';
import React, { useEffect, useMemo } from 'react';
import { OptionType } from 'components/Autocomplete';
import { concat, differenceWith } from 'ramda';
import { SelectField } from 'components/form/SelectField';
import { useTranslation } from 'react-i18next';
import { CACHE_AND_NETWORK } from 'lib/cache';
import { useQueryCached } from 'components/utils/graphql';
import { dateToUtc } from 'components/utils/utils';
import {
  AthleteOptions, CategoryOptions, KpiOptions, TagOptions, SeriesTrendState,
} from './SeriesInputs2';

type AthletePropsIn = {
  teamId: string,
  athletes?: OptionType[],
  label?: string,
  fullWidth?: boolean,
  showTags?: boolean,
  showValue?: boolean,
  enableCloseOnSelect?: boolean,
  onSelect: (selected: OptionType[]) => void,
  required?: boolean,
  withRoleOptions?: boolean,
  multiple?: boolean,
  defaultValueSelected?: boolean,
  activeAddSeries?: boolean,
  disabled?: boolean,
}
type AthleteOptionsPropsIn = {
  athletes: AthleteOptions,
  athleteList: AthleteOptions[],
  addSeries?: boolean;
  allowAthleteMultivalue: boolean,
  roles: object,
  statsType: string;
  defaultValueSelected: boolean;
  setCurrentValues: (series: SeriesTrendState) => void;
  startDate: string;
  endDate: string;
}
type CategoryOptionsPropsIn = {
  disabled: boolean,
  categories: CategoryOptions,
  categoryOptions: CategoryOptions[],
  label?: string,
  required?: boolean,
  onSelect: (selected: {id:string, name:string, color:string}[]) => void,
}
type KpiOptionsPropsIn = {
  addSeries: boolean,
  kpis: KpiOptions,
  kpiOptions: KpiOptions[],
  statsType:string,
  setCurrentValues: () => void;
}
type TagOptionsPropsIn = {
  disabled: boolean,
  tags: TagOptions,
  tagOptions: TagOptions[],
  multiple?: boolean,
  label?: string,
  onSelect: (selected: {id:string, name:string}[]) => void,
}
type MatchSelectorPropsIn = {
  label: string,
  value: [{id:string, value: string}] | [],
  options: [{id:string, value: string}] | null,
  onSelect: (selected: OptionType[]) => void,
}

export function capitalize(name: string) {
  if (!name) return '';
  const cleanedName = name.replace(/\s+/g, ' ').trim(); // remove possible extra white spaces
  const splt = cleanedName.toLowerCase().split(' ').map((t) => t[0].toUpperCase() + t.substring(1));
  return splt.join(' ');
}

export function AthleteListSelector(props: AthletePropsIn) {
  const { t } = useTranslation();
  const {
    teamId, withRoleOptions, defaultValueSelected, activeAddSeries, label, fullWidth, required, disabled, enableCloseOnSelect, athletes, multiple, onSelect,
  } = props;
  const WHICH_QUERY = !withRoleOptions ? ATHLETES : ATHLETES_AND_ROLES;
  const { loading, error, data } = useQueryCached<{res: AthleteTypeRes}|AthleteAndRolesRes, AthleteTypeVars>(WHICH_QUERY, {
    variables: {
      teamId,
      sort: 'last_name',
      hasAthletesession: true,
      inUse: true,
    },
    ...CACHE_AND_NETWORK,
    onCompleted: (data) => {
      if (defaultValueSelected && !activeAddSeries) {
        const athleteList = data.res?.content.map((a) => ({ id: a.id, value: a.name ? capitalize(a.name) : `${t('track.widgets.labels.athleteNull')}`, groupBy: 'athlete' })) || [];
        onSelect(athleteList);
      }
    },
  });

  if (error) return <div>Error...</div>;

  const roles = loading
    ? []
    : withRoleOptions
      ? [{ id: 'team', value: 'TEAM', groupBy: 'role' }].concat((data as AthleteAndRolesRes).resRoles?.content.map((r) => ({ id: r.id, value: r.name, groupBy: 'role' })) || [])
      : [];
  const athletesList = loading
    ? []
    : data[props.withRoleOptions ? 'resAthletes' : 'res']?.content.map((a) => ({ id: a.id, value: a.name ? capitalize(a.name) : `${t('track.widgets.labels.athleteNull')}`, groupBy: 'athlete' })) || [];

  return (
    <SelectField
      autocomplete
      id="athlete-selector"
      label={label}
      fullWidth={fullWidth || false}
      loading={loading}
      disableClearable={false}
      required={required}
      disabled={disabled}
      onChange={(v) => {
        onSelect(v);
      }}
      options={concat(roles, athletesList)}
      disableCloseOnSelect={!enableCloseOnSelect}
      value={athletes !== null ? athletes : (defaultValueSelected ? athletes : [])}
      multiple={multiple}
    />
  );
}

export function AthletesListSelector(props: AthleteOptionsPropsIn) {
  const {
    athletes, athleteList, addSeries, allowAthleteMultivalue, statsType, defaultValueSelected, setCurrentValues, startDate, endDate,
  } = props;
  const { t } = useTranslation();

  // only players present in the team on the selected interval
  const filteredAthleteList = useMemo(() => athleteList.filter((obj) => {
    // if trend team or role, include the object.
    if (!obj.hasOwnProperty('startDate')) return true;

    const playerStartDate = obj.startDate ? dateToUtc(new Date(obj.startDate)) : null;
    const playerEndDate = obj.endDate ? dateToUtc(new Date(obj.endDate)) : null;
    const startDateInterval = dateToUtc(new Date(startDate));
    const endDateInterval = dateToUtc(new Date(endDate));

    return (
      (!playerStartDate || new Date(playerStartDate) <= new Date(endDateInterval))
        && (!playerEndDate || new Date(playerEndDate) >= new Date(startDateInterval))
    );
  }), [athleteList, athletes, startDate, endDate]);

  useEffect(() => {
    if (statsType === 'summary') {
      if (athletes === null) {
        setCurrentValues((prev) => ({ ...prev, athletes: filteredAthleteList }));
      }
    }
  }, []);

  return (
    <SelectField
      autocomplete
      id="athlete-selector"
      label={statsType === 'summary'
        ? t('stats.drawer.athletes', 'athletes')
        : t('table.athlete', 'athlete')}
      fullWidth
      disableClearable={false}
      required
      disabled={addSeries && statsType === 'summary'}
      onChange={(a) => {
        if (!allowAthleteMultivalue) {
          const athlete = differenceWith((x, y) => x.id === y.id, a, athletes || []);
          setCurrentValues((prev) => ({ ...prev, athletes: athlete }));
          return;
        }
        setCurrentValues((prev) => ({ ...prev, athletes: a }));
      }}
      options={filteredAthleteList}
      disableCloseOnSelect={allowAthleteMultivalue}
      value={athletes !== null ? athletes : defaultValueSelected ? filteredAthleteList : []}
      multiple={statsType === 'summary'}
    />
  );
}

export function CategoryListSelector(props: CategoryOptionsPropsIn) {
  const {
    disabled, label, required, onSelect, categories, categoryOptions,
  } = props;

  return (
    <SelectField
      disabled={disabled}
      autocomplete
      multiple
      id="categories-selector"
      label={label || 'categories'}
      fullWidth
      required={required}
      onChange={(selected) => {
        const tmp = selected.map((s) => ({
          id: s.id,
          value: s.value,
          color: categoryOptions.find((c) => c.id === s.id)?.color || '',
        }));
        onSelect(tmp);
      }}
      options={categoryOptions || []}
      value={categories || []}
    />
  );
}

export function KpiListSelector(props: KpiOptionsPropsIn) {
  const {
    statsType, addSeries, kpis, kpiOptions, setCurrentValues,
  } = props;

  const { t } = useTranslation();

  return (
    <SelectField
      autocomplete
      id="KPI"
      fullWidth
      loading={false}
      label={t('stats.kpi', 'KPI')}
      required
      multiple={statsType === 'radar'}
      options={kpiOptions || []}
      maxNumberItems={statsType === 'radar' ? 5 : null}
      value={kpis || []}
      disabled={addSeries && statsType === 'radar'}
      onChange={(v) => {
        if (statsType === 'radar') {
          setCurrentValues((prev) => ({ ...prev, KPI: v }));
          return;
        }
        const tmpArr = (kpis || []).map((k) => k.id);
        const kpi = v.length === 1 ? v : v.filter((k) => !tmpArr.includes(k.id));
        setCurrentValues((prev) => ({ ...prev, KPI: kpi }));
      }}
    />
  );
}

export function TagListSelector(props: TagOptionsPropsIn) {
  const {
    disabled, tags, tagOptions, multiple, label, onSelect,
  } = props;

  return (
    <SelectField
      disabled={disabled}
      autocomplete
      multiple={multiple}
      id="tags-selector"
      fullWidth
      label={label || 'tags'}
      onChange={(selected) => {
        const tmp = selected.map((s) => ({
          id: s.id,
          value: s.value,
        }));
        onSelect(tmp);
      }}
      options={tagOptions || []}
      value={tags || []}
    />
  );
}

export function MatchSelector(props: MatchSelectorPropsIn) {
  // use it as MatchDistance/Match Cycle Selector
  const {
    label, value, options, onSelect,
  } = props;

  return (
    <SelectField
      fullWidth
      autocomplete
      id="athlete"
      label={label}
      value={value}
      onChange={(selected) => {
        const tmp = selected.map((s) => ({
          id: s.id,
          value: s.value,
        }));
        onSelect(tmp);
      }}
      options={options || []}
    />
  );
}
