import { Box, Paper } from '@material-ui/core';
import React, { useState } from 'react';
import { getPrimaryColorGradient } from '../utils/colors';
import { SelectField } from 'components/form/SelectField';
import { SNACKBAR_TIMEOUT } from 'components/constants';
import { useSnackbar } from 'notistack';

const getGradient = (palette: string) => {
  const gradients = [1, 2, 3, 4, 5, 6].map((num, index) => {
    const colors = getPrimaryColorGradient(num, palette);
    const isLastElement = index === [1, 2, 3, 4, 5, 6].length - 1;
    return (
      <Box
        display="flex"
        flexDirection="column"
        textAlign="center"
        key={num}
        style={{ marginRight: isLastElement ? '20px' : '0' }}
      >
        <b>{num}</b>
        {colors.map((c) => (
          <Box
            width="40px"
            height="30px"
            margin="4px"
            key={c}
            style={{ backgroundColor: c }}
          />
        ))}
      </Box>
    );
  });
  return gradients;
};

const TestGetGradientFn = () => {
const options = [
  {id: '0',value: 'green', label:'green original'},{id: '1',value: 'green2', label:'green secondary'},
  {id: '2',value: 'purple', label:'purple'},{id: '3',value: 'green-blue', label:'green blue'},
  {id: '4',value: 'green-red', label: 'green red'}, {id: '5',value: 'orange-red', label: 'orange red'},
  {id: '6',value: 'orange-red2', label: 'orange red 2'},{id: '7',value: 'blue', label: 'blue'},
  {id: '8',value: 'orange-blue', label: 'orange blue'}, {id: '9',value: 'redsalmon-blue', label: 'redsalmon blue'},
  {id: '10',value: 'green-yellow', label: 'green yellow'},
  ]
const [value, setValue] = useState(localStorage.getItem('colorsPalette') || 'purple')
const { enqueueSnackbar } = useSnackbar();
return (
  <>
    <Paper style={{padding:'0.5rem'}}>
      <div style={{width:'200px'}}>
    <SelectField
    label='active palette'
    value={value}
    options={options}
    onChange={(e) => {setValue(e.target.value)
      localStorage.setItem('colorsPalette', e.target.value)
      enqueueSnackbar(
       'palette successfully changed',
        {
          variant: 'success',
          autoHideDuration: SNACKBAR_TIMEOUT,
        },
      );
    }} />
    </div>
      <Box
        className="get-gradient-box"
        style={{flexWrap:'wrap'}}
      >
        {getGradient('green')}
        {getGradient('green2')}
        {getGradient('purple')}
        {getGradient('green-blue')}
        {getGradient('green-red')}
        {getGradient('orange-red')}
        {getGradient('orange-red2')}
        {getGradient('blue')}
        {getGradient('orange-blue')}
        {getGradient('redsalmon-blue')}
        {getGradient('green-yellow')}
      </Box>
    </Paper></>
)
}

export default TestGetGradientFn;
