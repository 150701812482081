import React from 'react';
import { Paper, Box, Typography } from '@material-ui/core';
import { IconWrapper } from '../lib/icons';

type PropsIn = {
    iconName: string;
    title: string;
    text: string;
    width?: string;
    height?: string;
    noMargins?: boolean;
    isUnorderedList?:boolean;
    bulletPoints?: true | [number];
    nLineSpace?: number;
    classNamePaper?: string;
    classNameMessage?: string;
}

export default function PageMessage(props:PropsIn) {
  const {
    iconName, title, text, noMargins, width, height, isUnorderedList, bulletPoints,
    nLineSpace, classNamePaper, classNameMessage,
  } = props;

  const ulText = text && typeof text === 'string' && text?.split('.');

  return (
    <Paper
      className={classNamePaper || ''}
      style={{ width: width || '600px', maxWidth: 'calc(100% - 8px)', height: height || 'fit-content' }}
    >
      <Box className={classNameMessage || ''} p={2} mt={noMargins ? undefined : 2}>
        <section className="pagemessage-box">
          <IconWrapper name={iconName} size="extralarge" color="primary" pointer />
          <div>
            <Typography variant="subtitle1" style={{ marginBottom: '5px' }}>{title}</Typography>
            {!isUnorderedList
              ? <Typography variant="subtitle2">{text}</Typography>
              : (
                <ul style={{ listStyle: 'disc' }}>
                  {ulText?.map((line, index) => (bulletPoints === true || bulletPoints?.includes(index)
                    ? (<li style={{ marginTop: nLineSpace === index ? '0.5rem' : '0.125rem' }}>{`${line}.`}</li>)
                    : (<p style={{ marginTop: '0.125rem' }}>{`${line}.`}</p>)))}
                </ul>
              )}
          </div>
        </section>
      </Box>
    </Paper>
  );
}
