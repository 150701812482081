import React, { useState } from 'react';
import { GridColDef, GridCellParams } from '@material-ui/x-grid';
import { PeriodFormatter } from 'components/stats/utils';
import { Paper, Box, IconButton } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { camelCaser } from 'components/utils/kpiLabelExtractor';
import { formatCategory } from 'components/athletesessions/utils';
import categoriesExtendedCell from 'components/CategoriesExtendedCell';
import tagsExtendedCell from 'components/TagsExtendedCell';
import { SeriesTrendState } from './SeriesInputs2';
import Table from '../Table';
import DeleteIcon from '@images/IconHeader/delete.svg';
import { useMutation } from '@apollo/client';
import { DELETE_STATSESSION_ROW } from 'mutation/stats';
import { SNACKBAR_TIMEOUT } from 'components/constants';
import { useSnackbar } from 'notistack';

type PropsIn = {
  teamId: string,
  tableRows: SeriesTrendState[];
  statsType: 'trend' | 'summary' | 'radar';
  updateLocalStorage: (e) => void;
  setRefetchStatus: (e) => void;
  setMyStats: (e) => void;
};

export default function SeriesTable(props: PropsIn) {
  const { teamId, updateLocalStorage, tableRows, statsType, setRefetchStatus, setMyStats } = props;
  const { t } = useTranslation();

  const isTrend = statsType === 'trend';
  const isSummary = statsType === 'summary';
  const isRadar = statsType === 'radar';
  const { enqueueSnackbar } = useSnackbar();

  const [deleteStatsessionRowId] = useMutation(DELETE_STATSESSION_ROW, {
    onCompleted: () => {
      enqueueSnackbar(
        t('', 'stat serie removed successfully'),
        {
          variant: 'success',
          autoHideDuration: SNACKBAR_TIMEOUT,
        },
      );
      setTimeout(() => {
        setRefetchStatus((prev) => prev + 1)
        setMyStats((prev) => ({
          ...prev,
          refetchMyStatsStatus: prev.refetchMyStatsStatus + 1,
        }));
    }, 250)
  }
  });

  const columns: GridColDef[] = [
    {
      field: 'number',
      headerName: '#',
      headerClassName: 'table-header__subtitle',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      width: 40,
      hideSortIcons: true,
      cellClassName: 'font-bold',
      renderCell: (params:GridCellParams) => params.row.number,
    },
    {
      field: 'period',
      headerName: t('stats.drawer.period', 'period'),
      headerClassName: 'table-header__subtitle',
      headerAlign: 'left',
      align: 'left',
      minWidth: 150,
      sortable: false,
      hideSortIcons: true,
      renderCell: (params:GridCellParams) => <span className="nolink-col-left">{params.row.period}</span>,
    },
    {
      field: 'grouping',
      headerName: t('stats.grouping', 'grouping'),
      headerClassName: 'table-header__subtitle',
      headerAlign: 'left',
      align: 'left',
      width: 130,
      sortable: false,
      hideSortIcons: true,
      hide: !isTrend,
      renderCell: (params:GridCellParams) => <span className="nolink-col-left">{params.row.grouping}</span>,
    },
    {
      field: 'athlete',
      headerName: t('table.athlete', 'athlete'),
      headerClassName: 'table-header__subtitle',
      headerAlign: 'left',
      align: 'left',
      width: 75,
      flex: 1,
      sortable: false,
      hideSortIcons: true,
      hide: isSummary,
      renderCell: (params:GridCellParams) => <span className="nolink-col-left">{params.row.athlete}</span>,
    },
    {
      field: 'KPI',
      headerName: t('stats.kpi', 'kpi').toUpperCase(),
      headerClassName: 'table-header__subtitle',
      headerAlign: 'left',
      align: 'left',
      flex:1,
      sortable: false,
      hideSortIcons: true,
      hide: isRadar,
      renderCell: (params:GridCellParams) => <span className="nolink-col-left">{params.row.KPI}</span>,
    },
    {
      field: 'categories',
      headerName: t('stats.drawer.categories', 'categories'),
      headerClassName: 'table-header__subtitle',
      headerAlign: 'left',
      align: 'left',
      width: 230,
      sortable: false,
      hideSortIcons: true,
      renderCell: (params: GridCellParams) => categoriesExtendedCell(params, 230),
    },
    {
      field: 'tags',
      headerName: t('stats.drawer.tags', 'tags'),
      headerClassName: 'table-header__subtitle',
      headerAlign: 'left',
      align: 'left',
      flex: 1,
      sortable: false,
      hideSortIcons: true,
      renderCell: (params: GridCellParams) => tagsExtendedCell(params, 480),
    },
    {
      field: 'edit',
      headerClassName: 'none',
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      hideSortIcons: true,
      minWidth: 65,
      renderCell: (params: GridCellParams) => (
         <IconButton
          className="table_button"
          size="small"
           onClick={() => {
            updateLocalStorage((prev) => {
              // remove statsessionrowid from localStorage:
              const updatedStatsSessionRowsID = prev[teamId].statsSessionRowsID.filter(
                (id) => id !== params.row.statSessionRowId
              );
                return {
                  ...prev,
                  [teamId]: {
                    ...prev[teamId],
                    statsSessionRowsID: updatedStatsSessionRowsID,
                  },
                };
            });
            deleteStatsessionRowId({variables: { statsessionRowId: params.row.statSessionRowId }});
           }}
          >
          <DeleteIcon />
           </IconButton>
      )
    },
  ];

  const rows = tableRows?.map((e, i) => ({
    id: i,
    statSessionRowId: e.statSessionRowId,
    number: i + 1,
    period: PeriodFormatter(e.period),
    grouping: e.groupingBy || '-',
    athlete: e.athletes?.map((a) => a.value).join(', ') || '-',
    KPI: e.KPI?.map((k) => k.value) || '-',
    categories: e.categories?.map((c) => `${formatCategory(t(`session.category.${camelCaser(c.value.toLowerCase())}`, `${c.value}`), c.color)}-`)
    || '-',
    tags: e.tags?.map((t) => t.value).join(', ') || '-',
  })) || [];

  return (
    <Paper>
      <Box p={2}>
        <Table disableCursor columns={columns} rows={rows} disableColumnFilter />
      </Box>
    </Paper>
  );
}
