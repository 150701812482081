import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import ScatterChart from '../charts/ScatterChart';
import { IconWrapper } from '../../lib/icons';
import { getUomTranslation, secondsToHHMMSS } from '../utils/utils';
import { eventsColors } from '../charts/colors';
import getAxisTitleFormat, { tooltipFormatter } from '../charts/utils';

interface PropsIn {
  data: any // @todo fix
}

export default function DivesDetails(props: PropsIn) {
  const { data } = props;
  const { t } = useTranslation();

  return (
    (data?.res?.imu?.divesDetails && (
      <Box className="chart-widget chart--scatter-chart">
        <ScatterChart
          xLabel={getAxisTitleFormat(
            t('track.widgets.metKPI.labels.pushingLoad', 'run up load'),
            data?.res?.imu?.divesDetails[0] ? getUomTranslation(t, data?.res?.imu?.divesDetails[0].x.uom) : '-',
          )}
          xIcon={<IconWrapper name="time" size="medium" color="primary" />}
          yLabel={getAxisTitleFormat(
            t('track.widgets.metKPI.labels.pushingDuration', 'pushing load'),
            data?.res?.imu?.divesDetails[0] ? getUomTranslation(t, data?.res?.imu?.divesDetails[0].y.uom) : '-',
          )}
          yIcon={<IconWrapper name="distance" size="medium" color="primary" />}
          height={600}
          data={[
            {
              name: 'dives',
              data: data?.res?.imu?.divesDetails.map((dot) => ({
                x: dot.x.value,
                y: dot.y.value,
                id: dot.id,
                color: eventsColors[dot.group],
                marker: {
                  symbol: dot.isLeft ? 'triangleLeft' : 'triangleRight',
                },
                tooltip: tooltipFormatter(dot.props.map((p) => ({
                  key: p.key,
                  value: p.value.unit === 'time' ? secondsToHHMMSS(p.value.value) : p.value.value,
                  uom: p.value.unit === 'time' ? '' : getUomTranslation(t, p.value.uom),
                  unit: p.value.unit,
                  valueType: 'CustomValueType',
                }))),
              })),
            },
            {
              type: 'line',
              name: 'Z0',
              data: [[0, data?.res?.imu?.divesDetailsBounds[0]], [data?.res?.imu?.divesDetailsBounds[0], 0]],
              color: 'var(--secondary-color)',
              showInLegend: false,
              marker: {
                enabled: false,
              },
              states: {
                hover: {
                  lineWidth: 0,
                },
              },
              enableMouseTracking: false,
            },
            {
              type: 'line',
              name: 'Z1',
              data: [[0, data?.res?.imu?.divesDetailsBounds[1]], [data?.res?.imu?.divesDetailsBounds[1], 0]],
              color: 'var(--secondary-color)',
              marker: {
                enabled: false,
              },
              showInLegend: false,
              states: {
                hover: {
                  lineWidth: 0,
                },
              },
              enableMouseTracking: false,
            },
          ]}
          xMin={0}
        />
      </Box>
    ))
    || null
  );
}
