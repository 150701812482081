import React, { useState, useEffect } from 'react';
import { HexColorPicker, HexColorInput } from 'react-colorful';
import { DEFAULT_GROUND_COLORS } from 'models/ground';
import useClickOutside from '../../lib/dom';
import { DEFAULT_COLORS } from '../constants';

interface PropsIn {
  defaultColor?: string
  disableGradientPicker?: boolean
  disabled?: boolean
  onChange?: (color: string) => void
  readonly?: boolean
  showDefaultColors?: boolean
  groundColors?: boolean
  style?: object;
  width?: string;
  height?: string;
}

export const ColorPickerDefaultColor = 'var(--primary-color)';

const ColorPicker = (props: PropsIn) => {
  const { ref, isOpen, setIsOpen } = useClickOutside(false);

  const {
    defaultColor, disabled, disableGradientPicker, readonly, showDefaultColors, groundColors,
    onChange, style, width, height,
  } = props;

  const [color, setColor] = useState(defaultColor || ColorPickerDefaultColor);

  useEffect(() => {
    if (defaultColor) {
      setColor(defaultColor);
    }
  }, [defaultColor]);

  const presetColors = groundColors ? DEFAULT_GROUND_COLORS : showDefaultColors ? DEFAULT_COLORS : undefined;

  const handleChangeColor = (c: string) => {
    setColor(c);
    if (onChange) {
      onChange(c);
    }
  };

  return (
    <div className={`color-picker__picker ${disabled ? 'disabled' : ''} ${readonly ? 'readonly' : ''}`} ref={ref} style={style}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        className="color-picker__swatch"
        style={{ backgroundColor: !disabled ? color : '#aaa', width: width || '28px', height: height || '28px' }}
        onClick={() => (disabled || readonly ? null : setIsOpen((prev) => !prev))}
      />

      {isOpen && (
        <div className="color-picker__popover">
          {
            !disableGradientPicker && <HexColorPicker color={color} onChange={handleChangeColor} />
          }
          <HexColorInput color={color} onChange={handleChangeColor} />

          <div className="color-picker__swatches">
            {presetColors && presetColors?.map((presetColor) => (
              // eslint-disable-next-line jsx-a11y/control-has-associated-label
              <button
                type="button"
                key={presetColor}
                className="color-picker__swatch"
                style={{ background: presetColor }}
                onClick={() => handleChangeColor(presetColor)}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ColorPicker;
