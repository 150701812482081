import React, {
  useState, useEffect, useContext, useMemo, useLayoutEffect, useRef,
} from 'react';
import Box from '@material-ui/core/Box';
import { eventsColors } from 'components/charts/colors';
import { GroundType, getOuterGroundColor } from 'models/ground';
import { ClubTypeRes, ClubTypeVars, CLUB } from 'query/club';
import { GroundRes, GroundVars, GROUND_DETAILS } from 'query/grounds';
import {
  Arrow, Circle, Group, Layer, Line, Stage, Path as KonvaPath,
} from 'react-konva';
import { Loader } from 'lib/icons';
import { CACHE_AND_NETWORK } from 'lib/cache';
import { useQueryCached, useLazyQueryCached } from 'components/utils/graphql';
import h337 from 'heatmap.js';
import { Html } from 'react-konva-utils';
import geomagnetism from 'geomagnetism';
import { COURSE_TRACK, PATH_DATA } from '../../../query/track';
import Basket from './background/Basket';
import Football from './background/Football';
import Futsal from './background/Futsal';
import Handball from './background/Handball';
import IceHockey from './background/IceHockey';
import Rugby from './background/Rugby';
import Error from '../../layout/Error';
import {
  DEFAULT_GROUND_COLOR,
  PATH_COLOR,
  PATH_WIDTH,
} from './constants';
import {
  findMinMaxX, findMinMaxY,
} from './utils';
import { CursorContext } from '../TrackChartContainer';
import { StringNumberGenericValueType } from '../../athlete/defines';
import { computeWorker } from '../../../workers';
import ShortTrack from './background/ShortTrack';
import { GPSGround } from '../../../lib/geo';
import GroundField from '../../webPlayer/GroundField';
import { coordsGPSToLPS, getSizesFromRef, isSetGlobal } from '../../utils/ground';
import GaelicFootball from './background/GaelicFootball';

type pePathType = number[][];
type pathType = {
  timestamp: number;
  x: number;
  y: number;
}[];
type PropsMarker = {
  pathData: pathType;
  cursor: number,
  timestamps: number[],
  rotationAngle: null | number,
};
type PropsPath = { pathData: pathType };
type PropsPowerEventsPath = { pathData: pathType; pePathData: pePathType; cursor: number };
type PropsHeatmap = { pathData: pathType };
type editDataProps = {
  groundType:string,
  groundSurfaceColor:string,
  vertexAX?: StringNumberGenericValueType,
  vertexAY?: StringNumberGenericValueType,
  vertexBX?: StringNumberGenericValueType,
  vertexBY?: StringNumberGenericValueType,
  vertexCX?: StringNumberGenericValueType,
  vertexCY?: StringNumberGenericValueType,
}
type PropsPitch = { defaultGround: boolean; groundData?: GroundType | editDataProps; scale: number; vertices?: boolean, showInDrawer?: boolean, };
type PropsIn = {
  teamId: string;
  trackId: string;
  athleteSessionId: string;
  currentDrill: number;
  groundId: string;
  mapMode?: string;
  // cursor: number;
  filters: {
    min?: number;
    max?: number;
  };
  setRenderGround?: (cursor: boolean) => void;
  setEnableButtons?: (cursor: boolean) => void;
};

type PreviewGroundProps = {
  groundId?: string,
  refetchStatus?: number,
  editData?: Partial<GroundType>,
  groundColor: string;
}

const Marker = React.memo((props: PropsMarker) => {
  const {
    pathData, cursor, rotationAngle, timestamps,
  } = props;

  const idx = cursor === 0 ? 0 : timestamps.findIndex((point) => Math.abs(cursor - point) < 400);

  return pathData[idx]
    ? rotationAngle
      ? (
        <Group x={pathData[idx].x} y={pathData[idx].y} offsetX={17} offsetY={21} rotation={rotationAngle}>
          <KonvaPath data="M13.7715 22.0564C16.5641 23.8395 20.2734 23.0211 22.0564 20.2285C23.8395 17.4359 23.0211 13.7266 20.2285 11.9436C17.436 10.1605 13.7267 10.9789 11.9436 13.7715C10.1605 16.5641 10.9789 20.2734 13.7715 22.0564Z" fill="#FFD600" />
          <KonvaPath data="M34 8.33333C34 7.57893 32.9474 7.5023 32.6467 8.19419C30.0563 14.1548 24.0259 18.3333 17 18.3333C9.97412 18.3333 3.94371 14.1548 1.35331 8.19419C1.05262 7.5023 0 7.57893 0 8.33333C0 17.5381 7.61116 25 17 25C26.3888 25 34 17.5381 34 8.33333Z" fill="#FFD600" />
        </Group>
      )
      : <Circle x={pathData[idx].x} y={pathData[idx].y} radius={5} fill="red" stroke="white" />
    : null;
});

const Path = React.memo((props: PropsPath) => {
  const { pathData } = props;

  const paths: number[][] = [[]];
  let lineNumber = 0;

  pathData.forEach((p) => {
    if (p.x === null || p.y === null) {
      lineNumber += 1;
      paths[lineNumber] = [];
    } else {
      paths[lineNumber].push(p.x);
      paths[lineNumber].push(p.y);
    }
  });

  return (
    <>
      {
      paths.map((path, idx) => (
        <Line
          /* eslint-disable-next-line react/no-array-index-key */
          key={idx}
          points={path}
          stroke={PATH_COLOR}
          strokeWidth={PATH_WIDTH}
        />
      ))
    }
    </>
  );
});

const PowerEventsPath = React.memo((props: PropsPowerEventsPath) => {
  const { pathData, pePathData, cursor } = props;

  const pePaths: { intensity: number; path: number[] }[] = [];
  const markerPath: pathType = [];

  pePathData?.forEach((pePoint) => {
    const start = Math.trunc(pePoint[0]);
    const end = Math.trunc(pePoint[1]);
    const intensity = pePoint[2];
    const path: number[] = [];

    pathData.forEach((point) => {
      if (point.timestamp >= start && point.timestamp <= end) {
        path.push(point.x);
        path.push(point.y);
        markerPath.push({ timestamp: point.timestamp, x: point.x, y: point.y });
      }
    });
    pePaths.push({ intensity, path });
  });

  return (
    <>
      {pePaths.map((path, idx) => (
        // eslint-disable-next-line react/no-array-index-key
        <Group key={`arrow-${idx}`}>
          <Arrow
            points={path.path}
            stroke={eventsColors[2]}
            fill={eventsColors[2]}
            strokeWidth={PATH_WIDTH}
          />
        </Group>
      ))}
      <Marker pathData={markerPath} cursor={cursor} />
    </>
  );
});

export const HeatMap = (props: PropsHeatmap & {
  offsetX: number,
  offsetY: number,
}) => {
  const {
    pathData, offsetX, offsetY,
  } = props;
  const groupRef = React.useRef(null);
  const heatmapInstancerRef = React.useRef<any>(null);

  const gradient = [
    'rgba(0, 0, 255, 0)',
    'rgba(0, 0, 255, 1)',
    'rgba(0, 31, 255, 1)',
    'rgba(0, 63, 255, 1)',
    'rgba(0, 91, 255, 1)',
    'rgba(0, 127, 255, 1)',
    'rgba(0, 159, 255, 1)',
    'rgba(0, 191, 255, 1)',
    'rgba(0, 223, 255, 1)',
    'rgba(0, 255, 255, 1)',
    'rgba(0, 255, 223, 1)',
    'rgba(0, 255, 191, 1)',
    'rgba(0, 255, 159, 1)',
    'rgba(0, 255, 127, 1)',
    'rgba(0, 255, 91, 1)',
    'rgba(0, 255, 63, 1)',
    'rgba(0, 255, 31, 1)',
    'rgba(0, 255, 0, 1)',
    'rgba(31, 223, 0, 1)',
    'rgba(63, 191, 0, 1)',
    'rgba(91, 159, 0, 1)',
    'rgba(127, 127, 0, 1)',
    'rgba(159, 91, 0, 1)',
    'rgba(191, 63, 0, 1)',
    'rgba(223, 31, 0, 1)',
    'rgba(255, 0, 0, 1)',
  ];

  const { minX, maxX } = useMemo(() => findMinMaxX(pathData), [pathData]);
  const { minY, maxY } = useMemo(() => findMinMaxY(pathData), [pathData]);
  const [mapReady, setMapReady] = useState(false);

  const coordsReady = maxX && maxY && minX && minY;
  const rows: number[] = [];

  useLayoutEffect(() => {
    setTimeout(() => {
      if (groupRef.current) {
        heatmapInstancerRef.current = h337.create({
          container: groupRef.current,
          radius: 10,
          blur: 1,
          opacity: 0.5,
          gradient: gradient.reduce((acc, col, idx, arr) => {
            acc[`${idx / arr.length}`] = col;
            return acc;
          }, {}),
        });
        setMapReady(true);
      }
    }, 10);
  }, []);

  useMemo(() => {
    if (coordsReady !== undefined && pathData.length) {
      if (heatmapInstancerRef.current) {
        heatmapInstancerRef.current.setData({
          max: 15,
          min: 0,
          data: pathData.map((p) => ({
            x: p.x - offsetX,
            y: p.y - offsetY,
            value: 1,
          })),
        });
      }
    }
  }, [rows, pathData, coordsReady, mapReady]);

  return (
    <Html
      divProps={{
        style: {
          width: '100%',
          height: '100%',
          position: 'absolute',
          left: 0,
          top: 0,
          bottom: 0,
          right: 0,
        },
        id: 'heatmap-canvas-layer',
      }}
      transformFunc={(transformAttrs) => ({
        ...transformAttrs,
        x: transformAttrs.x + offsetX * transformAttrs.scaleX,
        y: transformAttrs.y + offsetY * transformAttrs.scaleY,
      })}
    >
      <div
        ref={groupRef}
        style={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          left: 0,
          top: 0,
          bottom: 0,
          right: 0,
        }}
      />
    </Html>
  );
};

export const Pitch = (props: PropsPitch) => {
  const {
    defaultGround,
    groundData,
    scale,
    vertices,
    showInDrawer,
  } = props;

  const groundType = (groundDataObj, type:string) => {
    if (type === 'BASKET') {
      return <Basket groundData={groundDataObj} scale={scale} vertices={vertices ?? false} />;
    } if (type === 'FOOTBALL') {
      return <Football groundData={groundDataObj} scale={scale} vertices={vertices ?? false} />;
    }
    if (type === 'GAELIC_FOOTBALL') {
      return <GaelicFootball groundData={groundDataObj} scale={scale} vertices={vertices ?? false} />;
    }
    if (type === 'FUTSAL') {
      return <Futsal groundData={groundDataObj} scale={scale} vertices={vertices ?? false} />;
    } if (type === 'HANDBALL') {
      return <Handball groundData={groundDataObj} scale={scale} vertices={vertices ?? false} />;
    } if (type === 'RUGBY' || groundDataObj?.groundType === 'RUGBY_HALF') {
      return <Rugby groundData={groundDataObj} scale={scale} vertices={vertices ?? false} />;
    } if (type === 'ICE_HOCKEY') {
      return <IceHockey groundData={groundDataObj} scale={scale} vertices={vertices ?? false} />;
    }
    if (type === 'SHORT_TRACK') {
      return <ShortTrack groundData={groundDataObj} scale={scale} vertices={vertices ?? false} showInDrawer={!!showInDrawer} />;
    }
    return null;
  };

  if (!groundData || defaultGround) {
    return null;
  }

  return groundType(groundData, groundData.groundType);
};

export default function Ground(props: PropsIn) {
  const {
    teamId, trackId, athleteSessionId, groundId, // cursor,
    filters, currentDrill, setRenderGround, setEnableButtons,
    mapMode: groundMapMode,
  } = props;

  const boxRef = useRef<HTMLDivElement | undefined>(undefined);
  const {
    WINDOW_WIDTH,
    WINDOW_HEIGHT,
  } = getSizesFromRef(boxRef);

  const boxWidth = boxRef.current?.clientWidth || WINDOW_WIDTH;
  const boxHeight = boxRef.current?.clientHeight || WINDOW_HEIGHT;

  const { cursor } = useContext(CursorContext);

  const [courseData, setCourseData] = useState<{ [k: number]: number }>({});
  const [geo, setGeo] = useState<number>(0);
  const [bearing, setBearing] = useState<number>(0);
  const [rotationAngle, setRotationAngle] = useState<number | null>(null);

  const zipped = false;

  const [loadDataPath, { loading: loadingPath, data: dataPath }] = useLazyQueryCached(PATH_DATA(trackId ? 'track' : 'session', zipped), {
    variables: trackId
      ? {
        trackId,
        drill: currentDrill,
      }
      : {
        id: athleteSessionId,
        drill: currentDrill,
      },
    onCompleted: () => {
      if (setEnableButtons) {
        setEnableButtons(true);
      }
    },
    ...CACHE_AND_NETWORK,
  });

  const [loadHeadingData] = useLazyQueryCached(
    COURSE_TRACK,
    {
      variables: {
        athletesessionId: athleteSessionId,
      },
      ...CACHE_AND_NETWORK,
      notifyOnNetworkStatusChange: true,
      onCompleted: (course) => {
        if (course.res?.data) {
          setCourseData(JSON.parse(course.res?.data)
            .reduce((acc, curr) => {
              acc[curr[0]] = curr[1];
              return acc;
            }, {}));
        }
      },
    },
  );

  // @todo ha senso lasciare il setTimeout ?

  useEffect(() => {
    setTimeout(() => {
      loadDataPath();

      if (athleteSessionId) {
        loadHeadingData();
      }
    }, 1);
  }, [athleteSessionId]);

  const { loading: loadingClub, data: dataClub } = useQueryCached<ClubTypeRes, ClubTypeVars>(CLUB, {
    variables: {
      id: teamId,
    },
    ...CACHE_AND_NETWORK,
  });

  const { loading: loadingGround, data: dataGround, refetch: refetchGround } = useQueryCached<GroundRes, GroundVars>(GROUND_DETAILS, {
    variables: {
      id: groundId,
    },
    ...CACHE_AND_NETWORK,
  });

  const [mapMode, setMapMode] = useState(groundMapMode || '1');
  const [defaultGround, setDefaultGround] = useState(true);
  const [zoom, setZoom] = useState(1);
  const [path, setPath] = useState<{timestamp: number, x: number, y: number}[]>([]);
  const [sizes, setSizes] = useState<{width: number, height: number, scale: number, offsetX: number, offsetY: number}>({
    width: 0,
    height: 0,
    scale: 0,
    offsetX: 0,
    offsetY: 0,
  });

  useEffect(() => {
    if (groundMapMode) {
      setMapMode(groundMapMode);
    }
  }, [groundMapMode]);

  useEffect(() => {
    if (groundId) {
      setDefaultGround(false);
    }
  }, [groundId]);

  const grounds = dataClub
    ? dataClub.res.club.groundSet.map((ground) => ({
      value: ground.id.toString(),
      label: ground.name || '-',
      onClick: () => {
        setDefaultGround(false);
        refetchGround({ id: ground.id.toString() });
      },
    }))
    : [];

  grounds.push({
    value: '',
    label: '-',
    onClick: () => {
      setDefaultGround(true);
    },
  });

  if (setRenderGround) {
    grounds.push({
      value: '',
      label: 'map',
      onClick: () => {
        setRenderGround(false);
      },
    });
  }

  if (typeof window.Worker !== 'undefined') {
    computeWorker.onmessage = (e: MessageEvent<{
      width: number,
      height: number,
      scale: number,
      offsetX: number,
      offsetY: number,
      pathData: {timestamp: number, x: number, y: number}[],
      trackId: string | undefined,
      athleteSessionId: string | undefined,
      currentDrill: number | undefined,
    }>) => {
      const {
        width,
        height,
        scale,
        offsetX,
        offsetY,
        pathData,
        trackId: trkId,
        athleteSessionId: athId,
        currentDrill: curDrill,
      } = e.data;

      if (trackId === trkId && athId === athleteSessionId && currentDrill === curDrill) {
        setSizes({
          width, height, scale, offsetX, offsetY,
        });
        setPath(pathData);

        setGeo(geomagnetism.model().point([pathData[0].x, pathData[0].y])?.decl || 0);
      }
    };
  }

  useEffect(() => {
    if (
      dataPath?.res?.path?.data
      && boxWidth && boxWidth > 1
      && boxHeight && boxHeight > 1
    ) {
      computeWorker.postMessage({
        action: 'groundPathData',
        config: {
          trackId,
          athleteSessionId,
          currentDrill,
          dataGround,
          defaultGround,
          filters,
          boxWidth,
          boxHeight,
        },
        data: dataPath.res.path.data,
        ground: dataGround?.res,
      });
    }
  }, [dataPath?.res?.path?.data, dataGround, filters, boxWidth, boxHeight]);

  const groundDetails = useMemo(() => {
    if (dataGround?.res.id && dataGround.res.groundCoordsType === 'GLOBAL' && isSetGlobal(dataGround.res)) {
      const {
        vertexA,
        vertexB,
        vertexC,
        vertexD,
        localBearing,
      } = coordsGPSToLPS({
        ground: dataGround?.res,
        WINDOW_WIDTH,
        WINDOW_HEIGHT,
      });

      setBearing(localBearing);

      return {
        ...dataGround?.res,
        ...{
          vertexAX: { ...dataGround?.res.vertexAX, ...{ value: vertexA.x } },
          vertexAY: { ...dataGround?.res.vertexAY, ...{ value: vertexA.y } },
          vertexBX: { ...dataGround?.res.vertexBX, ...{ value: vertexB.x } },
          vertexBY: { ...dataGround?.res.vertexBY, ...{ value: vertexB.y } },
          vertexCX: { ...dataGround?.res.vertexCX, ...{ value: vertexC.x } },
          vertexCY: { ...dataGround?.res.vertexCY, ...{ value: vertexC.y } },
          vertexDX: { ...dataGround?.res.vertexDX, ...{ value: vertexD.x } },
          vertexDY: { ...dataGround?.res.vertexDY, ...{ value: vertexD.y } },
        },
      };
    }

    return dataGround?.res;
  }, [dataGround?.res.id]);

  const timestamps = useMemo(() => path.map((e) => e.timestamp), [path]);
  const headingInstants = useMemo(() => Object.keys(courseData).map((k) => parseFloat(k)), [courseData]);

  useEffect(() => {
    if (courseData) {
      const point = headingInstants.find((pt) => pt >= cursor && Math.abs(pt - cursor) < 400);
      if (point && courseData && courseData[point]) {
        setRotationAngle(courseData[point] + geo - bearing);
      }
    }
  }, [headingInstants, cursor, courseData]);

  if (loadingPath || loadingGround || loadingClub) return <Loader />;

  return (
    <Box>
      <Box display="flex" justifyContent="center" width="100%">
        <Box
          className="ground-box"
          id="ground-box"
          position="relative"
          overflow="hidden"
          ref={boxRef}
        >
          <GroundField
            dataGround={groundDetails}
            scale={sizes.scale}
            WINDOW_WIDTH={WINDOW_WIDTH}
            WINDOW_HEIGHT={WINDOW_HEIGHT}
            cursor={cursor}
            offX={sizes.offsetX}
            offY={sizes.offsetY}
            setZoom={setZoom}
            zoom={zoom}
          >
            {mapMode === '1' ? (
              <Layer
                listening={false}
                id="path-layer"
                name="path-layer"
              >
                <Path
                  pathData={path}
                />
                <Marker
                  pathData={path}
                  cursor={cursor}
                  timestamps={timestamps}
                  rotationAngle={rotationAngle}
                />
              </Layer>
            ) : null}

            {mapMode === '2' ? (
              <Layer
                listening={false}
              >
                <HeatMap
                  pathData={path}
                  offsetX={sizes.offsetX}
                  offsetY={sizes.offsetY}
                />
              </Layer>
            ) : null}
            {mapMode === '3' ? (
              <Layer
                listening={false}
              >
                <PowerEventsPath pathData={path} pePathData={dataPath?.res?.powerEventsPath?.data} cursor={cursor} />
              </Layer>
            ) : null}
          </GroundField>
        </Box>
      </Box>
    </Box>
  );
}

export function PreviewGround(props: PreviewGroundProps) {
  const {
    groundId, refetchStatus, editData, groundColor,
  } = props;

  const boxRef = useRef<HTMLDivElement | null>(null);
  const [boxWidth, setBoxWidth] = useState(1);
  const [boxHeight, setBoxHeight] = useState(1);

  const {
    WINDOW_WIDTH,
    WINDOW_HEIGHT,
  } = getSizesFromRef(boxRef);

  useLayoutEffect(() => {
    setBoxWidth(boxRef.current?.clientWidth || WINDOW_WIDTH);
    setBoxHeight(boxRef.current?.clientHeight || WINDOW_HEIGHT);
  }, []);

  const {
    // loading: loadingGround,
    data: dataGround,
    error: errorGround,
    refetch,
  } = useQueryCached<GroundRes, GroundVars>(GROUND_DETAILS, {
    variables: {
      id: groundId,
    },
    skip: !groundId,
    ...CACHE_AND_NETWORK,
  });

  if (editData?.vertexCX?.value) {
    editData.vertexCX.value = typeof editData?.vertexCX?.value === 'number'
      ? editData?.vertexCX?.value
      : parseFloat(editData?.vertexCX?.value);
  }

  if (editData?.vertexCY?.value) {
    editData.vertexCY.value = typeof editData?.vertexCY?.value === 'number'
      ? editData?.vertexCY?.value
      : parseFloat(editData?.vertexCY?.value);
  }

  useEffect(() => {
    if (refetchStatus && groundId) {
      refetch();
    }
  }, [refetchStatus]);

  const currentGround = editData?.vertexAX?.value
    ? editData
    : dataGround?.res;

  const {
    vertexA,
    vertexC,
    scale,
    scaledWidth,
    scaledHeight,
  } = GPSGround(currentGround, boxWidth, boxHeight);

  if (!currentGround) {
    return null;
  }

  if (errorGround) return <Error />;

  return (
    <Box
      position="relative"
      ref={boxRef}
      bgcolor={getOuterGroundColor(groundColor) || getOuterGroundColor(DEFAULT_GROUND_COLOR)}
    >
      <Stage
        offsetX={vertexA.x - (boxWidth - scaledWidth) / 2}
        offsetY={vertexC.y - (boxHeight - scaledHeight) / 2}
        width={boxWidth}
        height={boxHeight}
      >
        <Layer>
          <Pitch
            defaultGround={false}
            groundData={editData || dataGround?.res}
            scale={scale}
            vertices
            showInDrawer
          />
        </Layer>
      </Stage>
    </Box>
  );
}
