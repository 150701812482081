import { gql } from '@apollo/client';
import { UserType } from '../models/user';

export type updateUserProfileVars = {
  userProfileId: string,
  createUserProfileVars,
}

export type createUserProfileRes = {
  res: UserType,
}

export const UPDATE_USERROLES = gql`
    mutation UpdateUserRoles(
        $userProfileId: ID,
        $firstName: String,
        $lastName: String,
        $email: String!,
        $preferredLanguage: String,
        $isActive: Boolean,
        $newRoles: [UserRoleInputType]!,
    ) {
        res: updateUserRoles(
            userProfileId: $userProfileId,
            firstName: $firstName,
            lastName: $lastName,
            email: $email,
            preferredLanguage: $preferredLanguage,
            isActive: $isActive,
            newRoles: $newRoles,
        ) {
            ...on UserProfileType {
              id
              firstName,
              lastName,
              email,
              preferredLanguage,
              isActive
          }
            ... on CustomErrorType {
              errKey
              errVal {
                transKey
                transFallback
              }
            }
        }
    }
`;

export const DELETE_USERPROFILE = gql`
    mutation DeleteUserProfile(
        $userId: ID!,
        $clubId: ID!
    ) {
        res: deleteUserprofile(
            userId: $userId,
            clubId: $clubId
        )
    }
`;

export const UPDATE_USERPREFERENCES = gql`
  mutation UpdateUserPreferences(
        $userId: ID!,
        $lastName: String,
        $firstName: String,
        $preferredLanguage: String,
        $speed: PortalUserUnitsSpeedChoices,
        $power: PortalUserUnitsPowerChoices,
        $energy: PortalUserUnitsEnergyChoices,
        $percentage: PortalUserUnitsPercentageChoices,   
        $temperature: PortalUserUnitsTemperatureChoices, 
  ) {
    res: updateUserpreferences(
        userId: $userId,
        lastName: $lastName,
        firstName: $firstName,
        preferredLanguage: $preferredLanguage,
        speed: $speed,
        power: $power,
        energy: $energy,
        percentage: $percentage,
        temperature: $temperature,
    ) {
      id
      lastName
      firstName
      preferredLanguage
      userunits {
        speed
        power
        energy
        percentage
        temperature
      }
    }
  }
`;

export enum SpeedUnitsChoices {
  M_S = 'm/s',
  KM_H = 'km/h',
  M_MIN = 'm/min',
}

export enum PowerUnitsChoices {
  W_KG = 'W/kg',
  ML_O2_KG_MIN= 'ml O₂/kg·min',
}

export enum EnergyUnitsChoices {
  J_KG ='J/kg',
  ML_O2_KG = 'ml O₂/kg',
  KCAL_KG = 'kcal/kg',
}

export enum PercentageUnitsChoices {
  _P = '%',
  N = '',
}

export enum TemperatureUnitsChoices {
  C = '°C',
  F = '°F',
}

export const RESET_USER_PASSWORD = gql`
    mutation RequestResetUserPassword (
        $email: String!,
    ) {
        requestResetUserPassword(
            email: $email,
        ) {
            success
            messageKeys
        }
    }
`;

export interface ForceResetUserPasswordVars {
  email: string,
}

export interface ForceResetUserPasswordRes {
  forceResetUserPassword: {
    success: boolean,
    messageKeys: string[]
  }
}

export const FORCE_RESET_USER_PASSWORD = gql`
    mutation ForceResetUserPassword (
        $email: String!,
    ) {
        forceResetUserPassword(
            email: $email,
        ) {
            success
            messageKeys
        }
    }
`;

export const GET_WP_TOKEN = gql`
    mutation wpToken{
        res: wpTokenValidity
    }
`;
