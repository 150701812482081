import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { GroundType } from 'models/ground';
import { Column } from '../layout/Column';
import { SelectField } from '../form/SelectField';
import { FieldsGroup } from '../form/FieldsGroup';
import Checkbox from '../form/Checkbox';
import { Row } from '../layout/Row';
import { OptionType } from '../Autocomplete';

interface Props {
  ground?: GroundType;
  groundSet: GroundType[];
  setGround: React.Dispatch<React.SetStateAction<GroundType>>;
  groundOptions: OptionType[];
  jerseyOrNumber: boolean;
  setJerseyOrNumber: React.Dispatch<React.SetStateAction<boolean>>;
  trailsEnabled: boolean;
  setTrailsEnabled: React.Dispatch<React.SetStateAction<boolean>>;
}

const WebPlayerHeader = (props: Props) => {
  const { t } = useTranslation();
  const {
    ground,
    groundSet,
    setGround,
    groundOptions,
    jerseyOrNumber,
    setJerseyOrNumber,
    trailsEnabled,
    setTrailsEnabled,
  } = props;

  const handleGroundSelectChange = useCallback((e) => {
    const selectedName = e.target.value;
    const selectedGround = groundSet.filter((g) => g.name === selectedName)[0];

    setGround(selectedGround);
  }, [groundSet]);

  const handleJerseySelectChange = useCallback(() => {
    setJerseyOrNumber((prev) => !prev);
  }, []);

  const handleTrailsSelectChange = useCallback(() => setTrailsEnabled((prev) => !prev), [])

  return (
    <Row style={{ paddingLeft: '10px' }}>
      <Column xs={3}>
        <SelectField
          required
          fullWidth
          label={t('grounds.ground', 'ground')}
          value={ground?.name || ''}
          options={groundOptions}
          onChange={handleGroundSelectChange}
        />
      </Column>
      <Column xs={3}>
        <FieldsGroup
          label={`${t('athlete.widgets.label.shortName', 'short name')}/${t('athlete.widgets.label.jersey', 'jersey')}`}
          onChange={handleJerseySelectChange}
          options={[
            {
              value: true,
              label: t('athlete.widgets.label.shortName', 'short name'),
            },
            {
              value: false,
              label: t('athlete.widgets.label.jersey', 'jersey'),
            },
          ]}
          type="radio"
          value={jerseyOrNumber}
        />
      </Column>
      <Column xs={2}>
        <Checkbox
          value={trailsEnabled}
          label={t('session.labels.trails', 'trails')}
          onClick={handleTrailsSelectChange}
        />

      </Column>
    </Row>
  );
};

export default memo(WebPlayerHeader);
