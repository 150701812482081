import { gql } from '@apollo/client';
import {
  getLimitedTeamFragment, getLimitedTeamFragmentName,
  getTeamFragment, getTeamFragmentName, TeamResponseType, TeamType,
} from 'models/team';

export type TeamsVars = {
  clubId?: string,
  first?: number,
  skip?: number,
  sort?: string,
  active?: boolean,
}
export type TeamsRes = {
  res: {
    content: TeamType[],
    count: number,
    offset: number,
    pageSize: number,
  },
}
export type SingleTeamVars = {
  id : string,
  getCategory?: boolean,
  getTags?: boolean,
  activeOn?: Date | string | null,
}
export type SingleTeamTypeRes = {
  res: TeamType,
}

export const TEAMS = gql`
    query Teams (
        $clubId: ID,
        $first: Int,
        $skip: Int,
        $sort: String,
        $active: Boolean,
    ) {
        res: teams (
            clubId: $clubId,
            first: $first,
            skip: $skip,
            sort: $sort,
            active: $active
        ) {
            content {
                id
                sport
                league
                season
                startDate
                endDate
                locked
            }
            count
            offset
            pageSize
        }
    }
`;

export const TEAMS_PLAYERSET = gql`
    query TeamsPlayerset (
        $clubId: ID,
        $first: Int,
        $skip: Int,
        $sort: String,
        $active: Boolean,
    ) {
        res: teams (
            clubId: $clubId,
            first: $first,
            skip: $skip,
            sort: $sort,
            active: $active
        ) {
            content {
              id
              name
              season
              playerSet {
                id
                athlete {
                  id
                  name
                }
              }
            }
        }
    }
`;

export const TEAM_SELECTOR = gql`
    query TeamSelector (
        $clubId: ID,
        $first: Int,
        $skip: Int,
        $sort: String,
        $active: Boolean,
    ) {
        res: teams (
            clubId: $clubId,
            first: $first,
            skip: $skip,
            sort: $sort,
            active: $active
        ) {
            content {
                id
                season
                limitedClub {
                    name
                }
                sport
                name
                startDate
            }
            count
            offset
            pageSize
        }
    }
`;

export const GET_TEAM = gql`
  ${getTeamFragment(['with_club', 'with_teamsessiontagSet', 'with_teamsessioncategory'])}
  query Team (
    $id: ID!,
  ) {
    res: team (
      id: $id,
    ) {
      ...${getTeamFragmentName(['with_club', 'with_teamsessiontagSet', 'with_teamsessioncategory'])}
    }
  }
`;

export const GET_TEAM_TEAMSESSIONS = gql`
  ${getTeamFragment(['with_teamsessionset'])}
  query TeamTeamSessions (
    $id: ID!,
  ) {
    res: team (
      id: $id,
    ) {
      ...${getTeamFragmentName(['with_teamsessionset'])}
    }
  }
`;
export const GET_TEAM_PLAYERS = gql`
  ${getTeamFragment(['with_playerset'])}
  query TeamPlayers (
    $id: ID!, $getCategory: Boolean!, $getTags: Boolean!, $activeOn: Date,
  ) {
    res: team (
      id: $id, activeOn: $activeOn,
    ) {
      ...${getTeamFragmentName(['with_playerset'])}
        teamsessioncategorySet @include(if: $getCategory){
            id, name, color
        }
        teamsessiontagSet @include(if: $getTags){
            id, name
        }
    }
  }
`;

export const GET_TEAM_PARAMETERS = gql`
  ${getTeamFragment(['with_parameters'])}
  query TeamParameters (
    $id: ID!,
  ) {
    res: team (
      id: $id,
    ) {
      ...${getTeamFragmentName(['with_parameters'])}
        hasTracks
        layout {
            key
            order
            value {
                value
                key
            }
        }
    }
  }
`;

export const ALLOW_KPI_REPROCESS = gql`
    query AllowKpiReprocess (
        $id: ID!,
    ) {
        res: team (
            id: $id,
        ) {
            hasTracks
            club {
                allowKpiReprocess {
                  allowed
                  teamsInvolved
                }
            }
        }
    }
`;

export const GET_CLUB_TEAMS = gql`
  query ClubTeams {
    res: getClubTeams {
      id
      sport
      season
      startDate
      endDate
    }
  }
`;

export const GET_TEAM_RPES = gql`
    query TeamRPEs (
        $id: ID!,
    ) {
        res: team (
            id: $id,
        ) {
            hasRpes
        }
    }
`;

export const ME = gql`
${getLimitedTeamFragment(['with_club'])}
  query Me {
    res: me {
      id
      isSuperuser
      firstName
      lastName
      email
      isStaff
      isActive
      isLastTeamGk
      lastTeam {
        ...${getLimitedTeamFragmentName(['with_club'])}
      }
      preferredLanguage
      userunits {
        speed
        power
        energy
        percentage
        temperature
      }
      limitedUserroleSet {
        roleType
        athlete {
          name
        }
        team {
          id
          name
          season
        }
        club {
          name
        }
      }
      template {
        id
        kpi{
          name, unit, uom, value
        }
      }
    }
  }
`;
export const NAME_TEAMS = gql`
  ${getTeamFragment(['with_club'])}
  query NameTeams( $id:ID! ) {
    res: team( id: $id ){
      ...${getTeamFragmentName(['with_club'])}
    }
  }
`;

export const GET_PLAYERS = gql`
  query GetPlayers($teamId: ID, $athleteId: ID, $activeOn: Date) {
    res: players(teamId: $teamId, athleteId: $athleteId, activeOn: $activeOn) {
      content {
        id
        startDate
        endDate
        number
        hasTracks
        athlete {
          isDeletable
        }
        team {
          id
          name
          startDate
          endDate
          league
          season
          locked
          playingroleSet {
            name
            id
          }
        }
        playingrole {
          id
          name
        }
      }
  }
  }
`;

export const REPROCESS_DURATION_ESTIMATION = gql`
    query ReprocessDurationEstimation (
        $teamId: ID!,
    ) {
        res: reprocessDurationEstimation (
            teamId: $teamId,
        ) {
            start
            durationMin
            durationMax
        }
    }
`;

export const HAS_TEAM_SESSIONS = gql`
    query HasTeamSessions(
        $teamId: ID!,
    ) {
        res: hasTeamSessions(
            teamId: $teamId,
        )  
    }
`;
