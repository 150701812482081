import React, { useState, useEffect } from 'react';
import { TextField } from 'components/form/TextField';
import Box from '@material-ui/core/Box';
import { Row } from 'components/layout/Row';
import { Column } from 'components/layout/Column';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useTranslation, Trans } from 'react-i18next';
import { Dropzone, uploadFile } from 'components/form/Dropzone';
import { CREATE_ISSUE } from 'mutation/youtrack';
import { useMutation } from '@apollo/client';
import { withMe } from 'components/MeUserWrapper';
import { MeType } from 'models/me';
import { useSnackbar } from 'notistack';
import CLIENT_SETTINGS from 'lib/client_settings';
import { OutlinedWrapper } from './form/FieldsGroup';
import { SNACKBAR_TIMEOUT_LONGER } from './constants';
import ScreenVideoCapture from './ScreenVideoCapture';
import { UAParser } from 'ua-parser-js';
import { DEVICES, DeviceTypeRes, DeviceTypeVars } from 'query/device';
import { CACHE_AND_NETWORK } from 'lib/cache';
import { useLazyQueryCached, useQueryCached } from './utils/graphql';
import { Alert } from '@material-ui/lab';

type PropsIn = {
  teamId?: string,
  setContentOpen: (open: boolean) => void,
  me: MeType,
  sendIssue: boolean,
  setSendIssue: (b: boolean) => void,
  setBlockSend: (b: boolean) => void,
}

const UserReportedDrawer = (props: PropsIn) => {
  const {
    me, teamId, setContentOpen, sendIssue, setSendIssue, setBlockSend,
  } = props;

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const currentPage = window.location.href;
  const [files, setFiles] = useState([]);
  const [screenshotFiles, setScreenshotFiles] = useState([]);
  const [videoFiles, setVideoFiles] = useState([]);
  const totalFiles = [...files, ...screenshotFiles, ...videoFiles];
  const commonPlaceholder = t('issues.content.placeholder.common', 'Insert a detailed description of the problem you experienced.');
  const specificPlaceholders = {
    '0-8': t('issues.content.placeholder.hardware', 'Please insert one report per each device.'),
    '0-4': t('issues.content.placeholder.bridge', 'Please specify which version of the gpexe bridge and what operative system you are using (e.g.: 5.01, Windows 10).'),
    '0-1': t('issues.content.placeholder.webApp', 'Please specify which browser and version you are using (e.g.: Chrome, version 71).'),
    '0-3': t('issues.content.placeholder.ipadApp', 'Please specify which version of the gpexe live iPad app you are using, and what firmware version is installed on the gpexe link (e.g.: iPad app 4.4, link firmware 2.9).'),
  };
  const { userAgent } = navigator;
  const parser = new UAParser(userAgent);
  const browser = parser.getBrowser();
  const opSystem = parser.getOS();
  const browserInfo = `${browser.name} - ${browser.version}`;
  const opSystemInfo = `${opSystem.name} - ${opSystem.version}`;
  const feVersion = CLIENT_SETTINGS.public.gpexeFeVersion;
  const beVersion = JSON.parse(localStorage.be_version);
  const form = CLIENT_SETTINGS.public.youtrackFormUUID;
  const [issueStates, setIssueStates] = useState({
    projectIssue: '',
    projectName: '',
    titleIssue: '',
    bodyIssue: '',
    issueId: null,
    serialNumber: '',
  });
  const isWebApp = issueStates.projectIssue === '0-1';
  const isIpadApp = issueStates.projectIssue === '0-3';
  const isBridge = issueStates.projectIssue === '0-4';
  const isHardware = issueStates.projectIssue === '0-8';

  const getFormUUID = (form) => {
    if (isWebApp) return form.webapp;
    if (isIpadApp) return form.ipadapp;
    if (isBridge) return form.bridge;
    return form.hardware;
  };

  const formUUID = getFormUUID(form);

  const getFormFields = () => {
    if (isWebApp) {
    return {
      environment: "193-36",
      club: "193-37",
      team: "193-38",
      reporterName: "193-40",
    };
    } else if (isIpadApp) {
    return {
      environment: "193-45",
      club: "193-46",
      team: "193-47",
      reporterName: "193-48",
    };
    } else if (isBridge) {
     return {
      environment: "193-49",
      club: "193-50",
      team: "193-51",
      reporterName: "193-52",
    };
   } else {
     return {
      environment: "193-41",
      club: "193-42",
      team: "193-43",
      reporterName: "193-44",
    };
  }
};

const handleSerialNumbers = (sNumbers: string) => {
  const sNumbersList = sNumbers.split(/[,\s;]+/).filter(Boolean);
  return sNumbersList;
}

const serialNumbers = handleSerialNumbers(issueStates?.serialNumber?.toUpperCase());
const serialNumbersRecap = isHardware ? `<strong>Serial Numbers Recap:</strong> ${issueStates?.serialNumber?.toUpperCase()}` : '';

const [getDevices] = useLazyQueryCached<DeviceTypeRes, DeviceTypeVars>(DEVICES, {
  variables: {
    clubId: me?.lastTeam?.club?.id || '',
    serialIds: serialNumbers,
  },
  ...CACHE_AND_NETWORK,
});

  const formFields = getFormFields();
  const helpDeskUrl = `https://exelio.youtrack.cloud/api/feedbackForms/${formUUID}/submit`;
  const currentUrl = window.location.href;
  const parsedUrl = new URL(currentUrl);
  const hostname = parsedUrl.hostname;
  const subdomain = hostname.split('.')[0];
  const environment = subdomain.replace("-ui", "");
  const clubName = me?.lastTeam?.club?.name;
  const teamName = `${me?.lastTeam?.name} ${me?.lastTeam?.season}`;
  const reporterName = `${me?.firstName} ${me?.lastName}`

  const globalFields = [
    {
      $type: 'SimpleIssueCustomField',
      id: `${formFields.environment}`,
      value: `${environment}`,
    },
    {
      $type: 'SimpleIssueCustomField',
      id: `${formFields.club}`,
      value: `${clubName}`,
    },
    {
      $type: 'SimpleIssueCustomField',
      id: `${formFields.team}`,
      value: `${teamName}`,
    },
    {
      $type: 'SimpleIssueCustomField',
      id: `${formFields.reporterName}`,
      value: `${reporterName}`,
    },
  ]

  const getHardwareFields = (deviceId: string, serialId: string, uniqueId: string) => {
    const hardwareFields = [
      {
      $type: 'SimpleIssueCustomField',
      id: '193-35',
      value: deviceId,
    },
    {
      $type: 'SimpleIssueCustomField',
      id: '193-33',
      value: serialId,
    },
    {
      $type: 'SimpleIssueCustomField',
      id: '193-34',
      value: uniqueId,
    }]
    return hardwareFields;
  }
  
  const createHelpDeskIssue = (serialNumber: string = '', hardwareFields: object[] | undefined = undefined) => {
    // request payload
    const formData = new FormData();
    formData.append(
      'feedbackForm',
      JSON.stringify({
        email: `${me?.email}`,
        summary: `${issueStates.titleIssue}`,
        description: `${issueStates.bodyIssue}\n${serialNumber}\n${serialNumbersRecap}\n<strong>From email:</strong> ${me?.email}\n<strong>From Page:</strong> ${currentPage}\n<strong>FE ver:</strong> ${feVersion}\n<strong>BE ver:</strong> ${beVersion}\n<strong>OS:</strong> ${opSystemInfo}\n<strong>Browser:</strong> ${browserInfo}`,
        fields: isHardware && hardwareFields ? [...globalFields, ...hardwareFields] : globalFields,
        articleViews: [],
      })
    );
    // include attachments
    if (totalFiles.length) {
      totalFiles.forEach((file) => {
        formData.append('files', file);
      });
    }
    const options = {
      method: 'POST',
      body: formData,
    };
    fetch(helpDeskUrl, options)
      .then(() => enqueueSnackbar(
        <Trans
          i18nKey="user.dialog.issueCreate"
          defaults="issue {{value}} successfully created"
          values={{ value: '' }}
        />,
        {
          variant: 'success',
          autoHideDuration: SNACKBAR_TIMEOUT_LONGER,
        },
      )).catch((error) => console.error('Error:', error));
  };

  useEffect(() => {
    if (sendIssue) {
      if (isHardware && issueStates.serialNumber) {
        getDevices().then((devices) => {
          const devicesData = devices?.data?.res?.content;
          serialNumbers.forEach((sNumber) => {
            const relatedDevice = devicesData?.find((obj) =>  obj.serialId === sNumber)
            const hardwareFields = getHardwareFields(relatedDevice?.deviceId || '', relatedDevice?.serialId || '', relatedDevice?.uniqueId || '')
            const serialNumber = relatedDevice ? `<strong>Serial Number</strong>: ${relatedDevice.serialId}` : `<strong>Unknown Serial Number (device not found in club)</strong>: ${sNumber}`;
            createHelpDeskIssue(serialNumber, hardwareFields);
          })
        })
      }
      else {
        createHelpDeskIssue();
      }
      setContentOpen(false);
      setSendIssue(false);
    }
  }, [sendIssue]);

  useEffect(() => {
    setBlockSend(!issueStates.projectIssue || !issueStates.titleIssue || !issueStates.bodyIssue);
  });

  return (
    <Box>
      <Box display="flex" flexDirection="row">
        <Column xs={12}>
          <Row>
            <Column xs={12}>
              <OutlinedWrapper label={`${t('issues.content.category', 'category')} *`}>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  value={issueStates.projectIssue}
                  name="radio-buttons-group"
                  row
                  onChange={(v) => {
                    setIssueStates({
                      ...issueStates,
                      projectIssue: v.target.value,
                      projectName: v.target.id,
                    });
                  }}
                >
                  <FormControlLabel id="HARDWARE" value="0-8" control={<Radio />} label={t('issues.content.hardware', 'hardware')} />
                  <FormControlLabel id="BRIDGE" value="0-4" control={<Radio />} label="bridge" />
                  <FormControlLabel id="WEB APP" value="0-1" control={<Radio />} label={t('issues.content.webapp', 'webapp')} />
                  <FormControlLabel id="IPAD APP" value="0-3" control={<Radio />} label={t('issues.content.ipadapp', 'ipad app')} />
                </RadioGroup>
              </OutlinedWrapper>
            </Column>
          </Row>
          <Row>
            <Column xs={12}>
              <TextField
                label={t('issues.content.title', 'title')}
                onChange={(e) => setIssueStates({ ...issueStates, titleIssue: e.target.value })}
                value={issueStates.titleIssue}
                required
              />
            </Column>
          </Row>
          {isHardware
        && (
        <Row>
          <Column xs={12}>
            <Box>
              <Alert severity="warning">{`*${t('issues.content.placeholder.serialNumbersInfo', 'to add multiple devices, separate the serial numbers with a comma')} (e.g. U000001, U000002)`}</Alert>
            </Box>
          </Column>
          <Column xs={12}>
            <TextField
              label={t('issues.content.serialNumber', 'serial number')}
              onChange={(e) => setIssueStates({ ...issueStates, serialNumber: e.target.value })}
              value={issueStates.serialNumber}
            />
          </Column>
        </Row>
        )}
          <Row>
            <Column xs={12}>
              <TextField
                required
                multiline
                minRow={5}
                value={issueStates.bodyIssue}
                label={t('issues.content.description', 'description')}
                placeholder={`${commonPlaceholder} ${specificPlaceholders[issueStates.projectIssue]}`}
                onChange={(e) => setIssueStates({ ...issueStates, bodyIssue: e.target.value })}
              />
            </Column>
          </Row>
          <Row>
            <Column xs={12}>
              <Dropzone
                showFileNames
                autoUpload={false}
                dropzoneMaxSize={20000000}
                method="POST"
                files={files}
                setFiles={setFiles}
              />
            </Column>
          </Row>
        </Column>
      </Box>
      <ScreenVideoCapture
        screenshotFiles={screenshotFiles}
        setScreenshotFiles={setScreenshotFiles}
        videoFiles={videoFiles}
        setVideoFiles={setVideoFiles}
      />
    </Box>
  );
};

export default withMe(UserReportedDrawer);
