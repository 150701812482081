import { AthleteType, getAthleteFragment, getAthleteFragmentName } from './athlete'
import { ClubType, getClubFragment, getClubFragmentName, LimitedClubType } from './club';
import { getTeamFragment, getTeamFragmentName, LimitedTeamType, TeamType } from './team';
import {
  EnergyUnitsChoices,
  PercentageUnitsChoices,
  PowerUnitsChoices,
  SpeedUnitsChoices, TemperatureUnitsChoices,
} from '../mutation/user'

export type UserType = {
  id: string,
  isSuperuser: boolean,
  createdOn?: Date,
  updatedOn?: Date,
  firstName: string,
  lastName: string,
  email: string,
  isStaff: boolean,
  isActive: boolean,
  dateJoined: Date,
  lastTeam?: TeamType,
  // preferredLanguage: PortalUserProfilePreferredLanguageChoices!
  hasBeenWelcomed: boolean,
  lastUsablePassword: string,
  lastPasswordChange: Date,
  isInactivityGraceActive: boolean,
  reactivationDate?: Date,
  // templateSet: [TemplateType!]!
  // updatedTemplatesSet: [TemplateType!]!
}
export type UserRoleSetType = {
  id: string,
  createdOn: Date,
  updatedOn: Date,
  userProfile: UserType,
  club: ClubType,
  team: TeamType,
  roleType: 'ADMIN' | 'OPERATOR' | 'SUPERVISOR' | 'READER' | 'BRIDGE',
  athlete: AthleteType,
}

export type LimitedUserRoleSetType = {
  club: LimitedClubType,
  team: LimitedTeamType,
  roleType: 'ADMIN' | 'OPERATOR' | 'SUPERVISOR' | 'READER' | 'BRIDGE',
  athlete: AthleteType,
}

export type UserUnitsType = {
  createdOn: Date,
  updatedOn: Date,
  id: string,
  energy: EnergyUnitsChoices,
  percentage: PercentageUnitsChoices,
  power: PowerUnitsChoices,
  speed: SpeedUnitsChoices,
  temperature: TemperatureUnitsChoices,
}

export const getUserProfileFragmentName = () => {
  return 'UserProfileTypeFields'
}
export const getUserProfileFragment = () => {
  return `
    ${getTeamFragment([])}
    fragment ${getUserProfileFragmentName()} on UserProfileType {
      id
      isSuperuser
      createdOn
      updatedOn
      firstName
      lastName
      email
      isStaff
      isActive
      lastTeam {
        ...${getTeamFragmentName([])}
      }
    }
  `
}


export const getUserRoleSetFragmentName = () => {
  return 'UserRoleSetTypeFields'
}
export const getUserRoleSetFragment = () => {
  return `
    ${getAthleteFragment(['no_thumbnail'])}
    ${getTeamFragment([])}
    fragment ${getUserRoleSetFragmentName()} on UserRoleType {
      id     
      roleType
      club{
        id
        name
      }
      team{
        ...${getTeamFragmentName([])}
      }
      athlete{
        ...${getAthleteFragmentName(['no_thumbnail'])}
      }
    }
  `
};
