import React, { useRef } from 'react';
import HighchartsReact from 'highcharts-react-official';
import * as highcharts from 'highcharts';
import HCRounded from 'highcharts-rounded-corners';
import ReactDOMServer from 'react-dom/server';
import { tooltipStyle, tooltipFormatter } from './utils';
import { getPrimaryColorGradient, colorsPalette } from '../utils/colors';
import { nFormatter } from '../../lib/numbers';
import {
  formatterChoice, percentageFormatter, UnitsType, UomType,
} from '../utils/unitsFormatter';
import { UNITS_LABELS_FORMATS } from '../utils/units';

HCRounded(highcharts);

type PropsIn = {
  absVal?: boolean,
  colorLeft?: string,
  colorRight?: string,
  dataLeft: number,
  dataRight: number,
  hideLegend?: boolean,
  isPercent?: boolean,
  leftIcon?: React.ReactElement,
  leftLabel?: string,
  leftLabelColor?: string,
  maxVal?: number,
  uom: UomType,
  unit: UnitsType,
  nameLeft?: string,
  nameRight?: string,
  offset?: number,
  pointWidth?: number,
  rightIcon?: React.ReactElement,
  rightLabel?: string,
  rightLabelColor?: string,
  title: string | null,
  centeredZero?: boolean,
  tooltipLeftKey: string,
  tooltipRightKey: string,
  tooltipLeftKeyPerc: string,
  tooltipRightKeyPerc: string,
}

type PropsInOneBar = {
  color?: string,
  data: number,
  hideLegend?: boolean,
  isPercent?: boolean,
  leftIcon?: React.ReactElement,
  leftLabel?: string,
  leftLabelColor?: string,
  maxVal?: number,
  measure: string,
  name: string,
  pointWidth?: number,
  rightIcon?: React.ReactElement,
  rightLabel?: string,
  rightLabelColor?: string,
  title: string,
}

const SimpleHorizontalChart = React.memo((props: PropsIn) => {
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  const [rightValue, leftValue] = [Math.abs(props.dataRight), Math.abs(props.dataLeft)];
  const localMax = Math.max(rightValue, leftValue);
  // se uno dei due valori è < 1/10 di maxVal, maxValue diventa il max tra i due; altrimenti non si legge il valore e la scala è troppo grande
  const maxValue = props.maxVal && localMax < 100 && (leftValue >= 0.1 * props.maxVal) && (rightValue >= 0.1 * props.maxVal)
    ? props.maxVal
    : localMax;
  const total = Math.abs(props.dataLeft) + Math.abs(props.dataRight);
  const isLabelInside = (value) => props.isPercent && Math.abs(value) > 20
      || !props.isPercent && (Math.abs(value) > 0.15 * total || Math.abs(value) > 1000);
  const colors = getPrimaryColorGradient(2);
  const labelsColors = colorsPalette === 'purple' ? getPrimaryColorGradient(3).slice(1) : getPrimaryColorGradient(2);

  const options: highcharts.Options = {
    credits: {
      enabled: false,
    },
    legend: {
      enabled: !props.hideLegend,
    },
    chart: {
      height: props.hideLegend ? 150 : 200,
      marginTop: 55,
      spacingLeft: 20,
      spacingRight: 20,
      animation: false,
    },
    plotOptions: {
      bar: {
        animation: false,
        borderRadius: 3,
        dataLabels: {
          enabled: true,
          style: {
            fontSize: '1rem',
          },
        },
      },
    },
    tooltip: {
      ...tooltipStyle,
      shared: false,
      split: false,
      headerFormat: '',
      formatter() {
        const { point } = this;
        const seriesIndex = point.series.index;
        const leftPercKey = props.tooltipLeftKeyPerc || `${props.tooltipLeftKey} %`;
        const rightPercKey = props.tooltipRightKeyPerc || `${props.tooltipRightKey} %`;
        const tooltipObject = [
          {
            key: seriesIndex === 0 ? props.tooltipLeftKey : props.tooltipRightKey,
            value: point.stackTotal < 0 ? -1 * point.stackTotal : point.stackTotal,
            uom: props.uom,
            unit: props.unit,
            valueType: 'CustomValueType',
          },
          {
            key: seriesIndex === 0 ? leftPercKey : rightPercKey,
            value: ((Math.abs(this.point.stackTotal) / total) * 100).toFixed(0),
            uom: '%',
            unit: 'percentage',
            valueType: 'CustomValueType',
          },
        ];
        return tooltipFormatter(tooltipObject);
      },
    },
    yAxis: {
      title: {
        text: props.uom,
      },
      endOnTick: true,
      min: props.centeredZero ? -maxValue : undefined,
      max: props.centeredZero ? maxValue : undefined,
      gridLineColor: 'var(--stroke-gray)',
      labels: {
        step: 1,
        autoRotation: undefined,
        formatter() {
          const value = typeof this.value === 'string' ? Number(this.value) : this.value;
          return nFormatter(Math.abs(value), 1);
        },
        style: {
          fontSize: '14px',
          fontFamily: 'var(--font-family-main)',
          color: 'var(--text-secondary)',
        },
      },
    },
    xAxis: [
      {
        min: 0,
        reversed: false,
        lineColor: 'var(--stroke-gray)',
        labels: {
          step: 1,
          enabled: false,
          useHTML: true,
          style: {
            textAlign: 'center',
            fontSize: '14px',
            fontFamily: 'var(--font-family-main)',
            color: 'var(--text-secondary)',
          },
        },
        tickLength: 0,
      },
      {
        min: 0,
        reversed: false,
        tickColor: 'transparent',
        lineColor: 'var(--stroke-gray)',
        labels: {
          step: 0,
          enabled: false,
        },
        opposite: true,
      },
    ],
    title: {
      text: props.title || '',
    },
    series: [{
      type: 'bar',
      data: [-1 * props.dataLeft],
      stacking: 'normal',
      name: props.nameLeft || 'walk',
      color: props.colorLeft || colors[0],
      // @ts-ignore
      borderRadiusBottomLeft: '0%',
      borderRadiusBottomRight: '0%',
      dataLabels: {
        formatter() {
          const absTotal = Math.abs(this.total);
          return (absTotal > 15
            ? formatterChoice(props.unit)(props.uom, absTotal) + (props.isPercent ? '%' : '')
            : '');
        },
        style: {
          textOutline: 'none',
          color: isLabelInside(props.dataLeft) ? 'white' : 'var(--secondary-color)',
        },
        inside: isLabelInside(props.dataLeft),
        align: isLabelInside(props.dataLeft) ? 'left' : 'right',
        allowOverlap: true,
        overflow: false,
      },
      pointPadding: 0.1,
      groupPadding: 0,
      pointWidth: 22,
    }, {
      type: 'bar',
      data: [props.dataRight],
      stacking: 'normal',
      name: props.nameRight || 'run',
      color: props.colorRight || colors[1],
      // @ts-ignore
      borderRadiusTopLeft: '0%',
      borderRadiusTopRight: '0%',
      dataLabels: {
        formatter() {
          return formatterChoice(props.unit)(props.uom, Math.abs(this.total)) + (props.isPercent ? '%' : '');
        },
        style: {
          textOutline: 'none',
          color: isLabelInside(props.dataRight) ? 'white' : 'var(--secondary-color)',
        },
        inside: isLabelInside(props.dataRight),
        align: isLabelInside(props.dataRight) ? 'right' : 'left',
        allowOverlap: true,
        overflow: false,
      },
      pointPadding: 0.1,
      groupPadding: 0,
      pointWidth: 22,
    }],
  };

  highcharts.SVGRenderer;

  return (
    <div className="simple-h-chart__wrapper">
      <div
        className="simple-h-chart__left"
        style={{
          color: props.leftLabelColor || labelsColors[0],
        }}
      >
        <span
          className="side-label"
        >
          {props.leftIcon}
          {props.leftLabel ? <b>{props.leftLabel}</b> : null}
        </span>
      </div>
      <div className="simple-h-chart__center">
        <HighchartsReact
          highcharts={highcharts}
          options={options}
          ref={chartComponentRef}
        />
      </div>
      <div
        className="simple-h-chart__right"
        style={{
          color: props.rightLabelColor || labelsColors[1],
        }}
      >
        <span
          className="side-label"
        >
          {props.rightIcon}
          {props.rightLabel ? <b>{props.rightLabel}</b> : null}
        </span>
      </div>
    </div>
  );
});

const SimpleHorizontalOneChart = React.memo((props: PropsIn) => {
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  const seriesName = props.nameRight || 'RPE';
  const colors = getPrimaryColorGradient(6).slice(1);
  const getSeriesColorByValue = (value:number) => (
    (value >= 0 && value <= 2) ? colors[0]
      : (value >= 3 && value <= 4) ? colors[1]
        : (value >= 5 && value <= 6) ? colors[2]
          : (value >= 7 && value <= 8) ? colors[3]
            : (value >= 9 && value <= 11) ? colors[4]
              : '');
  const options: highcharts.Options = {
    credits: {
      enabled: false,
    },
    legend: {
      enabled: !props.hideLegend,
    },
    chart: {
      height: props.hideLegend ? 150 : 200,
      marginTop: 55,
    },
    plotOptions: {
      bar: {
        borderRadius: 3,
        dataLabels: {
          enabled: true,
          style: {
            fontSize: '1rem',
          },
        },
      },
    },
    tooltip: {
      borderRadius: 9,
      backgroundColor: 'rgba(255, 255, 255, 0.90)',
      borderColor: 'rgba(0, 0, 0, 0.6)',
      shared: false,
      split: false,
      formatter() {
        const tooltipObject = [{
          key: seriesName,
          value: props.dataRight,
          uom: props.uom,
          unit: props.unit,
          valueType: 'CustomValueType',
        }];
        return tooltipFormatter(tooltipObject);
      },
    },
    yAxis: {
      title: {
        text: props.uom,
      },
      labels: {
        step: 1,
        formatter() {
          return nFormatter(this.value, 0);
        },
      },
      endOnTick: true,
      max: props.maxVal,
    },
    xAxis: [
      {
        min: 0,
        reversed: false,
        tickColor: 'transparent',
        lineColor: 'transparent',
        labels: {
          step: 1,
          enabled: false,
          useHTML: true,
          style: {
            'text-align': 'center',
            fontSize: '12px',
          },
        },
        tickLength: 0,
        title: {
          useHTML: true,
          text: `<span class="side-label">${ReactDOMServer.renderToStaticMarkup(props.leftIcon)}${props.leftLabel ? `<b>${props.leftLabel}</b>` : ''}</span>`,
          rotation: 0,
          textAlign: 'center',
          align: 'high',
          x: 45,
          y: -39,
          style: {
            color: props.leftLabelColor || 'var(--primary-gradient-2)',
          },
        },
        offset: props.offset !== undefined ? props.offset : 30,
      },
      {
        min: 0,
        reversed: false,
        tickColor: 'transparent',
        labels: {
          step: 0,
          enabled: false,
        },
        title: {
          useHTML: true,
          text: `<span class="side-label">${ReactDOMServer.renderToStaticMarkup(props.rightIcon)}${props.rightLabel ? `<b>${props.rightLabel}</b>` : ''}</span>`,
          rotation: 0,
          textAlign: 'center',
          align: 'high',
          x: -45,
          y: -39,
          style: {
            color: props.rightLabelColor || 'var(--primary-gradient-4)',
          },
        },
        opposite: true,
      },
    ],
    title: {
      text: props.title || '',
    },
    series: [{
      type: 'bar',
      data: [props.dataRight],
      stacking: 'normal',
      name: seriesName,
      color: props.colorRight || getSeriesColorByValue(props.dataRight),
      // @ts-ignore
      borderRadiusTopLeft: '50%',
      borderRadiusTopRight: '50%',
      dataLabels: {
        formatter() {
          const [barUnit, barUom] = props.isPercent ? ['percentage', UNITS_LABELS_FORMATS._P] : ['number', ' '];
          return formatterChoice(barUnit)(barUom, Math.abs(this.total));
        },
        style: {
          textOutline: 'none',
          color: '#fff',
        },
        padding: 10,
      },
      pointWidth: 22,
    }],
  };

  highcharts.SVGRenderer;

  return (
    <HighchartsReact
      highcharts={highcharts}
      options={options}
      ref={chartComponentRef}
    />
  );
});

export { SimpleHorizontalChart, SimpleHorizontalOneChart };
