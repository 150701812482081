import React from 'react';
import { rectSortingStrategy, defaultAnimateLayoutChanges, AnimateLayoutChanges } from '@dnd-kit/sortable';
import { MeasuringStrategy, UniqueIdentifier } from '@dnd-kit/core';
import { Sortable } from './fragments/Sortable';
import { GridContainer } from './fragments/GridContainer';

const Container = (p: any) => <GridContainer {...p} />;

/**
 * Componente per l'ordinamento di un elenco di child.
 *
 * @description Si possono usare i due eventi esposti per gestire cancellazione e ordinamento
 *
 * `onItemsMove` passa un array già ordinato dei child
 * `onItemsRemove` ritorna l'id del child rimosso
 *
 * @param props
 * @constructor
 */
const GridSortable = (props: {
  items: React.ReactElement[],
  columns?: number,
  onItemsMove?: (array: T[], from: number, to: number) => void,
  onItemsRemove?: (id: UniqueIdentifier) => void,
  onItemResize?: (id: UniqueIdentifier, size?: number) => void,
}) => {
  const {
    columns, items, onItemsMove, onItemsRemove, onItemResize,
  } = props;
  const animateLayoutChanges: AnimateLayoutChanges = (args) => defaultAnimateLayoutChanges({ ...args, wasDragging: true });

  return (
    <Sortable
      items={items}
      columns={columns}
      adjustScale
      handle={!!onItemsRemove}
      onItemsRemove={onItemsRemove}
      onItemResize={onItemResize}
      onItemsMove={onItemsMove}
      Container={Container}
      strategy={rectSortingStrategy}
      animateLayoutChanges={animateLayoutChanges}
      measuring={{ droppable: { strategy: MeasuringStrategy.Always } }}
    />
  );
};

export default GridSortable;
