import React from 'react';

interface PropsIn {
  width?: number
  height?: number
  percentage: number
  colorStart?: string
}

const HorizontalPercentageBar = (props: PropsIn) => {
  const {
    percentage, width, height, colorStart,
  } = props;

  if (Number.isNaN(percentage)) {
    return null;
  }

  const chartWidth = width || 500;
  const chartHeight = height || 20;
  const chartValue = percentage ? chartWidth * (1 - (percentage / 100)) : chartWidth;
  const chartColorStart = colorStart || 'var(--primary-gradient-3)';

  return (
    <div className="horizontal-percentage-bar" style={{ marginBottom: 3 }}>
      <svg
        width={chartWidth}
        height={chartHeight}
        version="1.1"
        viewBox={`0 0 ${chartWidth} ${chartHeight}`}
        xmlns="http://www.w3.org/2000/svg"
        overflow="visible"
      >
        <mask id="chartMask">
          <rect
            width={chartWidth}
            height={chartHeight}
            ry={Math.round(chartHeight / 2)}
            fill="white"
            fillRule="evenodd"
            strokeWidth="1"
          />
        </mask>
        <rect
          width={chartWidth}
          height={chartHeight}
          ry={Math.round(chartHeight / 2)}
          fill="white"
          fillRule="evenodd"
          strokeWidth="1"
          stroke="var(--stroke-gray)"
        />
        <rect
          width={chartWidth}
          height={chartHeight}
          x={-chartValue}
          fill={chartColorStart}
          fillRule="evenodd"
          strokeWidth="1"
          mask="url(#chartMask)"
        />
      </svg>
    </div>
  );
};

export default HorizontalPercentageBar;
