import React, { useLayoutEffect, useRef, useState } from 'react';
import HorizontalPercentageBar from './HorizontalPercentageBar';
import { formatterChoice, UnitsType, UomType } from '../utils/unitsFormatter';

interface PropsIn {
  title: string
  subtitle?: string
  percentage: number
  value?: string
  colorStart?: string
  uom?: UomType,
  unit: UnitsType,
}

const useElementSize = (ref: React.MutableRefObject<any>) => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      if (ref?.current) {
        setSize([ref.current.offsetWidth, ref.current.offsetHeight]);
      }
    }

    new ResizeObserver(updateSize).observe(ref.current);
    updateSize();
    return () => ref.current.removeEventListener('resize', updateSize);
  }, []);
  return size;
};

const HorizontalPercentageBarChart = (props: PropsIn) => {
  const {
    title, subtitle, percentage, colorStart, value, uom,
    unit,
  } = props;
  const chartRef = useRef(null);
  const [width] = useElementSize(chartRef);
  return (
    <div className="horizontal-percentage-bar-chart">

      <div className="hpbc-inner">
        <div className="chart" ref={chartRef}>
          <div className="title">
            <span id="zone-index">{title}</span>
            <span id="zone-legend" style={{ marginLeft: '3px' }}>{subtitle}</span>
          </div>
          <div className="percentage">
            {(percentage).toFixed() === '0' && percentage > 0 ? '~ ' : ''}
            {(percentage).toFixed()}
            %
          </div>
          <div className="horizontal-perc-bar-box">
            <HorizontalPercentageBar
              percentage={percentage || 0}
              colorStart={colorStart}
              width={width}
            />
            <div className="meta zone-value-uom">
              <span className="label--value value horizontal-perc-value">{value === '-' ? 0 : formatterChoice(unit)(uom, value)}</span>
              <span className="label--unit horizontal-perc-unit">{uom}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HorizontalPercentageBarChart;
