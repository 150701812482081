import React, {
  useState, useEffect, useContext, useRef,
} from 'react';
import { useMutation } from '@apollo/client';
import { SideDrawer } from 'components/layout/SideDrawer';
import BaseButton from 'components/form/BaseButton';
import { Box } from '@material-ui/core';
import CancelIcon from '@images/IconHeader/cancel.svg';
import { Column } from 'components/layout/Column';
import { Row } from 'components/layout/Row';
import { useTranslation } from 'react-i18next';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import { TextField } from 'components/form/TextField';
import { CREATE_OR_UPDATE_MY_STATS } from 'mutation/stats';
import { useSnackbar } from 'notistack';
import { SNACKBAR_TIMEOUT } from 'components/constants';
import { MyStatsContext } from 'Authenticator';
import Checkbox from 'components/form/Checkbox';
import { statsProperties } from './SeriesInputs2';

type PropsIn = {
    statsSessionId: string;
    openAddToMyStats: boolean;
    setOpenAddToMyStats: (b:boolean) => void;
    properties: statsProperties;
    setProperties: (s: statsProperties) => void
}

export default function AddToMyStatsDrawer(props: PropsIn) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const {
    statsSessionId, openAddToMyStats, setOpenAddToMyStats, properties, setProperties,
  } = props;

  const [name, setName] = useState<string|undefined>('');
  const [showInMyStats, setShowInMyStats] = useState(false);
  const [showInDashboard, setShowInDashboard] = useState(false);
  const { setMyStats } = useContext(MyStatsContext);
  const editValidation = (showInDashboard || showInMyStats) ? !!name : true;

  const [createOrUpdateMyStats] = useMutation(CREATE_OR_UPDATE_MY_STATS, {
    onError: () => enqueueSnackbar(t('dialog.general.somethingWentWrong', 'something went wrong'), {
      variant: 'error',
      autoHideDuration: SNACKBAR_TIMEOUT,
    }),
    onCompleted: () => {
      enqueueSnackbar(t('stats.dialog.propertiesSuccessfullyUpdated', 'properties successfully updated'), {
        variant: 'success',
        autoHideDuration: SNACKBAR_TIMEOUT,
      });
      setMyStats((prev) => ({
        ...prev,
        refetchMyStatsStatus: prev.refetchMyStatsStatus + 1,
      }));
    },
  });

  useEffect(() => {
    setName(properties.title);
    setShowInMyStats(properties.showMyStats);
    setShowInDashboard(properties.showDashboard);
  }, [properties]);

  useEffect(() => {
    if (showInDashboard) {
      setShowInMyStats(true);
    }
  }, [showInDashboard]);

  return (
    <SideDrawer
      size="small"
      title={t('stats.drawer.editProperties', 'edit properties')}
      open={openAddToMyStats}
      setOpen={setOpenAddToMyStats}
      actionsLeft={[
        <BaseButton
          key="cancel"
          startIcon={<CancelIcon />}
          onClick={() => {
            setOpenAddToMyStats(false);
          }}
          noMargins
        >
          {t('stats.drawer.button.cancel', 'cancel')}
        </BaseButton>,
      ]}
      actionsRight={[
        <BaseButton
          key="save"
          disabled={!editValidation}
          color="primary"
          marginRight
          isValidation={editValidation}
          onClick={() => {
            createOrUpdateMyStats({
              variables:
              {
                statsSessionId,
                myStatsName: name,
                showMyStats: showInMyStats,
                showDashboard: showInDashboard,
              },
            });
            setOpenAddToMyStats(false);
            setProperties({ title: name, showMyStats: showInMyStats, showDashboard: showInDashboard });
          }}
          startIcon={<SaveAltIcon />}
          noMargins
        >
          {t('drawer.actions.save', 'save')}
        </BaseButton>,
      ]}
    >
      <Box>
        <Column xs={12}>
          <Row>
            <Column xs={12}>
              <TextField
                required
                label={t('stats.labels.statName', 'stat name')}
                value={name}
                inputProps={{ maxLength: 50 }}
                fullWidth
                onChange={(e) => setName(e.target.value)}
              />
            </Column>
          </Row>
          <Row>
            <Column xs={12}>
              <Checkbox
                readonly={showInDashboard}
                value={showInMyStats}
                label={t('stats.drawer.showInMyStats', 'show in my stats')}
                onClick={() => setShowInMyStats((prev) => !prev)}
              />
            </Column>
          </Row>
          <Row>
            <Column xs={12}>
              <Checkbox
                value={showInDashboard}
                label={t('stats.labels.showInDashboard', 'show in dashboard')}
                onClick={() => setShowInDashboard((prev) => !prev)}
              />
            </Column>
          </Row>
        </Column>
      </Box>
    </SideDrawer>
  );
}
