import Grid, { GridSpacing } from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import React from 'react';

export interface DividerWithTextProps {
  textClassName?: string
  text: string
  spacing?: GridSpacing
  ySpacing?: boolean
  endComponent?: React.ReactNode
  startComponent?: React.ReactNode
}

function DividerWithText(props: DividerWithTextProps) {
  const {
    spacing, text, startComponent, endComponent, textClassName, ySpacing,
  } = props;

  return (
    <Grid
      container
      alignItems="center"
      spacing={spacing || 2}
      className={ySpacing ? 'divider-with-text' : ''}
    >
      {
        startComponent
        && <Grid item className="start-component">{startComponent}</Grid>
      }
      <Grid item xs />
      <Grid item className={textClassName}>{text}</Grid>
      <Grid item xs />
      {
        endComponent
        && <Grid item className="end-component">{endComponent}</Grid>
      }
    </Grid>
  );
}

export default DividerWithText;
