import * as React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Paper, Box } from '@material-ui/core';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import { globalEventsChannel } from '../lib/events';
import { Row } from './layout/Row';
import { Column } from './layout/Column';

interface TeamCheckWrapperProps {
  teamId?: string
  dataTeamId?: string
}

export const InvalidTeamMessage = () => {
  const { t } = useTranslation();

  return (
    <div className="central-message">
      <Paper elevation={12}>
        <Box p={3}>
          <Row>
            <Column md={2}>
              <Box height="100%" display="flex" justifyContent="center">
                <HourglassEmptyIcon fontSize="large" />
              </Box>
            </Column>

            <Column md={10}>
              <h3>{t('global.invalidTeam.title', 'Requested data is from another team')}</h3>
              <p>{t('global.invalidTeam.text', 'Please wait, changing team selection')}</p>
            </Column>
          </Row>
        </Box>
      </Paper>
    </div>
  );
};

const TeamCheckWrapper: React.FC<TeamCheckWrapperProps & {children: JSX.Element}> = (props) => {
  const {
    children,
    dataTeamId,
    teamId,
  } = props;

  useEffect(() => {
    setTimeout(() => {
      if (dataTeamId && teamId && teamId !== dataTeamId) {
        globalEventsChannel.emit('onTeamChange', dataTeamId);
      }
    }, 10);
  }, [dataTeamId, teamId]);

  return dataTeamId && teamId && teamId !== dataTeamId
    ? <InvalidTeamMessage />
    : children;
};

export default TeamCheckWrapper;
